import React, {useEffect, useState} from "react";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import Select from "react-select";
import {globalElementSelectStyle} from "../../../global/style/globalElementSelectStyle";
import {EURO_VALUE, GLOBAL_BORDER_RADIUS, SIZES} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import MainButton from "../../commons/mainButton";
import Snackbar from "../../commons/snackBar";
import Loader from "../../commons/loader";

function CreateItems({rentSelected, setRentSelected, rents, premiumPalette}) {

    const primaryColor = premiumPalette.primaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative
    const secondaryColor = premiumPalette.secondaryColor

    const stringInitialValue = ''
    const [cover, setCover] = useState(stringInitialValue)
    const [imageSearched, setImageSearched] = useState(null)
    const [searchBikeField, setSearchBikeField] = useState(stringInitialValue)
    const [name, setName] = useState(stringInitialValue)
    const [debouncedSearchBikeField, setDebouncedSearchBikeField] = useState(stringInitialValue)
    const [category, setCategory] = useState(stringInitialValue)
    const [size, setSize] = useState(stringInitialValue)
    const [minUserHeight, setMinUserHeight] = useState()
    const [maxUserHeight, setMaxUserHeight] = useState()
    const [sorting, setSorting] = useState()
    const [assignedRentCode, setAssignedRentCode] = useState(stringInitialValue)
    const [archived, setArchived] = useState(false)
    const [priceFirstDay, setPriceFirstDay] = useState()
    const [priceSecondDay, setPriceSecondDay] = useState()
    const [priceThirdDay, setPriceThirdDay] = useState()
    const [priceFourthDay, setPriceFourthDay] = useState()
    const [priceFifthDay, setPriceFifthDay] = useState()
    const [priceSixthDay, setPriceSixthDay] = useState()
    const [priceSeventhDay, setPriceSeventhDay] = useState()
    const [slotConfiguration, setSlotConfiguration] = useState([])
    const [description, setDescription] = useState(stringInitialValue)
    const [amount, setAmount] = useState(1)
    const [callCount, setCallCount] = useState(0)
    const [cantSaveItems, setCantSaveItems] = useState(false)
    const [itemSaved, setItemSaved] = useState(false)
    const [imageNotFound, setImageNotFound] = useState(false)
    const [errorDuringSearch, setErrorDuringSearch] = useState(false)
    const [loadingSearchBike, setLoadingSearchBike] = useState(false)
    const [loading, setLoading] = useState(false)

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 80
    }

    useEffect(() => {
        setSlotConfiguration(rentSelected.rentSlotConfiguration.map(slot => ({
            price: null,
            type: slot.type
        })))
    }, [rentSelected])

    useEffect(() => {
        if (searchBikeField === stringInitialValue) {
            return
        }

        const timer = setTimeout(() => {
            setDebouncedSearchBikeField(searchBikeField)
        }, 1500)
        return () => clearTimeout(timer)
    }, [searchBikeField])

    useEffect(() => {
        if (debouncedSearchBikeField) {
            setLoadingSearchBike(true)
            fetchBikeImage(debouncedSearchBikeField)
        }
    }, [debouncedSearchBikeField])

    const fetchBikeImage = async () => {
        const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
        const GOOGLE_SEARCH_ENGINE_ID = process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID
        const query = encodeURIComponent(searchBikeField)

        const url = `https://www.googleapis.com/customsearch/v1?q=${query}&searchType=image&fileType=jpg&key=${API_KEY}&cx=${GOOGLE_SEARCH_ENGINE_ID}`

        try {
            const response = await fetch(url)
            const data = await response.json()
            if (data.items && data.items.length > 0) {
                const imageUrl = data.items[0].link
                setImageSearched(imageUrl)
                convertToBase64(imageUrl)
            } else {
                setImageNotFound(true)
            }
            setLoadingSearchBike(false)
        } catch (error) {
            setLoadingSearchBike(false)
            alert('Errore nella ricerca.')
        }
    }

    const convertToBase64 = async (url) => {
        try {
            const response = await fetch(url)
            const blob = await response.blob()
            const reader = new FileReader()
            reader.onloadend = () => setCover(reader.result)
            reader.readAsDataURL(blob)
        } catch (error) {
            setErrorDuringSearch(true)
        }
    }

    function handleFileChange(event) {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setCover(reader.result)
                setImageSearched(reader.result)
            }
            reader.readAsDataURL(file)
        }
    }

    function handleSaveMultipleItems() {
        const atLeastOneValueMissing = cover === stringInitialValue || name === stringInitialValue || category === stringInitialValue ||
            size === stringInitialValue || !minUserHeight || !maxUserHeight || !sorting || assignedRentCode === stringInitialValue ||
            !priceFirstDay || !priceSecondDay || !priceThirdDay || !priceFourthDay || !priceFifthDay || !priceSixthDay || !priceSeventhDay ||
            slotConfiguration.filter(slot => !slot.price).length > 0 || description === stringInitialValue

        if (atLeastOneValueMissing) {
            setCantSaveItems(true)
            return
        }

        setLoading(true)
        const request = {
            rentId: rentSelected.rentId,
            coverJpg: cover,
            item: {
                isAvailable: true,
                images: ["first.jpg"],
                assignedRentCode: assignedRentCode,
                rent: {
                    rentId: rentSelected.rentId,
                    cover: "cover.jpg",
                    city: rentSelected.city,
                    street: rentSelected.street,
                    name: rentSelected.name,
                    categories: rentSelected.categories,
                    type: rentSelected.type,
                    email: rentSelected.email
                },
                singleDayPrices: slotConfiguration,
                cover: "cover.jpg",
                archived: archived,
                heightSuggested: [minUserHeight, maxUserHeight],
                size: size,
                sorting: sorting,
                name: name,
                category: category,
                prices: [
                    {
                        cost: priceFirstDay,
                        day: "1"
                    },
                    {
                        cost: priceSecondDay,
                        day: "2"
                    },
                    {
                        cost: priceThirdDay,
                        day: "3"
                    },
                    {
                        cost: priceFourthDay,
                        day: "4"
                    },
                    {
                        cost: priceFifthDay,
                        day: "5"
                    },
                    {
                        cost: priceSixthDay,
                        day: "6"
                    },
                    {
                        cost: priceSeventhDay,
                        day: "7"
                    }
                ],
                infos: [
                    {
                        description: description,
                        title: "Descrizione"
                    }
                ]
            }
        }

        const sleep = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        const fetchSequentially = async () => {
            for (let i = 0; i < amount; i++) {
                setCallCount(prevCount => prevCount + 1)
                try {
                    const rentSuperUserAdminCreateItemCommand = httpsCallable(functions, 'rentSuperUserAdminCreateItemCommand')
                    await rentSuperUserAdminCreateItemCommand(request)
                    await sleep(150)
                } catch (error) {
                    alert('Ooops! Qualcosa sembra essere andato storto.')
                }
            }
            setCallCount(0)
        }

        fetchSequentially()
            .then(() => {
                setItemSaved(true)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }

    function handleChangeAmount(value) {
        const newAmount = amount + value
        if (newAmount < 1 || newAmount > 20) {
            return
        }
        setAmount(newAmount)
    }

    function getRentCategories() {
        return rentSelected.categories.map(category => ({value: category, label: category}))
    }

    function getSizes() {
        return Object.keys(SIZES).map(size => ({value: size, label: size}))
    }

    return (
        <div style={pageStyle.container}>
            {loading ?
                <Loader absoluteFullPage={true}
                        absoluteFullPageText={callCount + ' su ' + amount}/> :
                <></>}
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Noleggio'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={pageStyle.nameRentsContainer}>
                        {rents.map(rent => {
                            return (
                                <div key={rent.rentId}>
                                    <MainButton text={rent.name}
                                                action={() => setRentSelected(rent)}
                                                backgroundColor={rentSelected.rentId === rent.rentId ? secondaryColor : primaryColorFirstAlternative}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Immagine'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                            <MyNormalText text={'Cerca la bici'}/>
                            <input style={globalElementInputStyle.customInput}
                                   type={'text'}
                                   value={searchBikeField}
                                   placeholder={'Marca e modello'}
                                   onChange={(e) => setSearchBikeField(e.target.value)}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                            <MyNormalText text={'Oppure carica la foto a mano'}/>
                            <input type={'file'}
                                   accept={'image/jpeg'}
                                   onChange={handleFileChange}/>
                        </div>
                    </div>
                    <div>
                        {imageSearched ?
                            loadingSearchBike ?
                                <div style={pageStyle.containerLoader}>
                                    <Loader/>
                                </div> :
                                <img src={imageSearched}
                                     alt={'Immagine bici'}
                                     style={pageStyle.coverBike}/> :
                            <></>}
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Informazioni base'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={name}
                               placeholder={'Nome'}
                               onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={assignedRentCode}
                               placeholder={'Codice magazzino'}
                               onChange={(e) => setAssignedRentCode(e.target.value)}/>
                    </div>
                    <div>
                        <Select styles={globalElementSelectStyle}
                                placeholder={'Categoria'}
                                onChange={(value) => setCategory(value.label)}
                                options={getRentCategories()}/>
                    </div>
                    <div>
                        <Select styles={globalElementSelectStyle}
                                placeholder={'Taglia'}
                                onChange={(value) => setSize(value.label)}
                                options={getSizes()}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={minUserHeight}
                               placeholder={'Altezza minima consigliata in cm'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setMinUserHeight(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={maxUserHeight}
                               placeholder={'Altezza massima consigliata in cm'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setMaxUserHeight(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={sorting}
                               placeholder={'Posizione in elenco'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setSorting(parseInt(price))
                                   }
                               }}/>
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Archivio'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <MyNormalText text={'Creare l\'articolo archiviato?'}/>
                    <input type="checkbox"
                           style={{width: 20, height: 20}}
                           checked={archived}
                           onChange={() => setArchived(!archived)}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Descrizione'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <textarea style={textAreaStyle}
                              value={description}
                              placeholder={'Inserisci la descrizione'}
                              onChange={(event) => setDescription(event.target.value)}
                              rows={description.split('\n').length}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Prezzi giorno singolo ' + EURO_VALUE}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    {rentSelected.rentSlotConfiguration.map((slot, index) => {
                        return (
                            <div key={index}
                                 style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                <MyNormalText text={slot.description}/>
                                <div>
                                    <input style={globalElementInputStyle.customInput}
                                           type={'number'}
                                           value={slot.price}
                                           placeholder={'Prezzo'}
                                           onChange={(e) => {
                                               const price = e.target.value
                                               if (price) {
                                                   slotConfiguration[index].price = parseInt(price)
                                                   setSlotConfiguration([...slotConfiguration])
                                               }
                                           }}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                    <MyNormalText text={'Prezzi su più giorni ' + EURO_VALUE}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText
                        text={'Esempio: Primo giorno: 60, secondo giorno: 55, terzo giorno 50, quarto giorno...'}
                        fontSize={14}/>
                </div>
                <div style={pageStyle.section}>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceFirstDay}
                               placeholder={'Primo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceFirstDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceSecondDay}
                               placeholder={'Secondo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceSecondDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceThirdDay}
                               placeholder={'Terzo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceThirdDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceFourthDay}
                               placeholder={'Quarto giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceFourthDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceFifthDay}
                               placeholder={'Quinto giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceFifthDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceSixthDay}
                               placeholder={'Sesto giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceSixthDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceSeventhDay}
                               placeholder={'Settimo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceSeventhDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Quantità'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={{display: 'flex', alignItems: 'center', width: 100, gap: 20}}>
                        <MainButton text={'-'}
                                    action={() => handleChangeAmount(-1)}
                                    backgroundColor={primaryColorFirstAlternative}/>
                        <MyNormalText text={amount}/>
                        <MainButton text={'+'}
                                    action={() => handleChangeAmount(+1)}
                                    backgroundColor={primaryColorFirstAlternative}/>
                    </div>
                </div>
            </div>
            <div>
                <MainButton text={'Salva'}
                            action={handleSaveMultipleItems}
                            disabled={loading}
                            backgroundColor={secondaryColor}/>
            </div>
            {cantSaveItems ?
                <Snackbar message={'Assicurati di aver compilato tutti i campi richiesti'}
                          duration={4000}
                          isWarning={true}
                          onClose={() => setCantSaveItems(false)}/> :
                <></>}
            {itemSaved ?
                <Snackbar message={'Censimento completato con successo'}
                          duration={4000}
                          isOk={true}
                          onClose={() => setItemSaved(false)}/> :
                <></>}
            {imageNotFound ?
                <Snackbar message={'Nessuna immagine trovata'}
                          duration={4000}
                          isWarning={true}
                          onClose={() => setImageNotFound(false)}/> :
                <></>}
            {errorDuringSearch ?
                <Snackbar message={'Sembra esserci un problema con la foto trovata. Prego cambiare ricerca.'}
                          duration={4000}
                          isAlert={true}
                          onClose={() => setErrorDuringSearch(false)}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    innerContainer: {
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    },
    nameRentsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    },
    containerLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        width: 300
    },
    coverBike: {
        width: 300,
        height: 'auto',
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default CreateItems
