import React, {useEffect, useState} from "react";
import {useGlobalState} from "../../global/globalState";
import {useLocation} from "react-router-dom";
import {websitePages} from "../../costants";

function MyIcon({icon, name, width, height, pointer, neverInvert}) {

    const location = useLocation()
    const {globalState, setGlobalState} = useGlobalState()
    const [invertedIconColor, setInvertedIconColor] = useState(false)

    useEffect(() => {
        calculateTextColor(globalState)
    }, [globalState.premiumBackgroundPalette])

    function calculateTextColor(globalState) {
        if (neverInvert) {
            return
        }
        if (location.pathname.includes(websitePages.DASHBOARD) && globalState.premiumBackgroundPalette.isDarkMode) {
            setInvertedIconColor(true)
        } else {
            setInvertedIconColor(false)
        }
    }

    const style = {
        display: 'flex',
        filter: invertedIconColor ? 'invert(1)' : undefined,
        width: width,
        height: height,
        cursor: pointer ? 'pointer' : undefined
    }

    return <img src={icon}
                alt={name}
                style={style}/>
}

export default MyIcon
