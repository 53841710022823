import React, {useState} from "react";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../../../App";
import {functions} from "../../../firebase";
import MainButton from "../../commons/mainButton";
import {httpsCallable} from "firebase/functions";
import {HIGHLIGHT_COLOR} from "../../../costants";
import Snackbar from "../../commons/snackBar";

function JoinTheNetwork({languageState, isMobileView}) {

    const [email, setEmail] = useState(null)
    const [body, setBody] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showInfoToUser, setShowInfoToUser] = useState(false)

    const [emailInputStyle, setEmailInputStyle] = useState(globalElementInputStyle.customInput)
    const [bodyInputStyle, setBodyInputStyle] = useState(globalElementInputStyle.customInputTextArea)

    function getPlaceholderEmail() {
        if (languageState.language === ITALIAN_LANGUAGE) {
            return 'La tua email'
        } else if (languageState.language === GERMAN_LANGUAGE) {
            return 'Ihre E-Mail'
        }
        return 'Your email'
    }

    function getPlaceholderBodyMessage() {
        if (languageState.language === ITALIAN_LANGUAGE) {
            return 'Il tuo nome, il nome del tuo negozio...'
        } else if (languageState.language === GERMAN_LANGUAGE) {
            return 'Ihr Name, der Name Ihres Geschäfts...'
        }
        return 'Your name, your store\'s name...'
    }

    function handleSendRequestToJoin() {
        if (!email) {
            setEmailInputStyle({...globalElementInputStyle.customInput, border: '3px solid ' + HIGHLIGHT_COLOR})
            return
        } else {
            setEmailInputStyle({...globalElementInputStyle.customInput, border: '3px solid ' + 'white'})
        }

        if (!body) {
            setBodyInputStyle({...globalElementInputStyle.customInputTextArea, border: '3px solid ' + HIGHLIGHT_COLOR})
            return
        } else {
            setBodyInputStyle({...globalElementInputStyle.customInputTextArea, border: '3px solid ' + 'white'})
        }

        setLoading(true)
        const rentJoinTheNetworkAlertCommand = httpsCallable(functions, 'rentJoinTheNetworkAlertCommand')
        rentJoinTheNetworkAlertCommand({email: email, body: body})
            .then(() => {
                setLoading(false)
                setShowInfoToUser(true)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <div style={pageStyle.container}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <MyNormalText textWithTranslation={'contact.us.title'}
                              bold={true}
                              language={languageState.language}/>
                <MyNormalText textWithTranslation={'contact.us.dont.work.with.us.yet'}
                              language={languageState.language}/>
            </div>
            <div style={pageStyle.form}>
                <input id={'email-address'}
                       style={emailInputStyle}
                       name={'email'}
                       type={'email'}
                       required
                       placeholder={getPlaceholderEmail()}
                       onChange={(e) => setEmail(e.target.value)}/>
                <textarea style={bodyInputStyle}
                          value={body}
                          placeholder={getPlaceholderBodyMessage()}
                          onChange={(e) => setBody(e.target.value)}/>
            </div>
            <div>
                <MainButton text={'action.send'}
                            action={handleSendRequestToJoin}
                            loading={loading}
                            disabled={loading}
                            isTranslated={true}
                            language={languageState.language}
                            secondaryImage={require('../../../imgs/actions/send.png')}
                            secondaryImageWidth={15}
                            secondaryImageHeight={15}/>
            </div>
            {showInfoToUser ?
                <Snackbar message={'thanks.to.reach.to.us'}
                          isOk={true}
                          language={languageState.language}
                          duration={6000}
                          onClose={() => setShowInfoToUser(false)}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        height: '100vh',
        gap: 20,
        margin: 20
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 20,
        maxWidth: 600
    }
}

export default JoinTheNetwork
