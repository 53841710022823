import React, {useEffect, useRef, useState} from "react";
import {ARCHIVED_COLOR, EURO_VALUE, GLOBAL_BACKGROUND_POPUP_ABSOLUTE, GLOBAL_BORDER_RADIUS} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import {getUrlImg} from "../../../global/firebaseStorageRepo";
import Loader from "../../commons/loader";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MainButton from "../../commons/mainButton";
import ExperienceImage from "./experienceImage";
import MyNormalText from "../../commons/myNormalText";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";
import UpdateExperience from "./updateExperience";

moment.locale('it');
const FONT_SIZE_TITLE = 18

function ExperienceDetail({rentUser, experienceId, premiumPalette, setIsOpen, openOperations, refreshExperiences}) {

    const primaryColorSecondAlternative = premiumPalette.primaryColorSecondAlternative

    const [experience, setExperience] = useState({})
    const [urlImages, setUrlImages] = useState([])
    const [loadingExperience, setLoadingExperience] = useState(true)

    const [loadingArchivingExperience, setLoadingArchivingExperience] = useState(false)
    const [loadingDuplicateExperience, setLoadingDuplicateExperience] = useState(false)

    const experienceDetailRef = useRef()

    useEffect(() => {
        getExperience()
    }, [])

    function handleOnEscape() {
        setIsOpen(false)
    }

    useMyEscapeListener(() => handleOnEscape())
    useMyClickOutListener(experienceDetailRef, () => handleOnEscape())

    function getExperience() {
        httpsCallable(functions, 'rentExperienceDetailView')({rentId: rentUser.rentId, experienceId: experienceId})
            .then((response) => {
                const experience = response.data.experience

                getDetailImages(experience)

                setExperience(experience)
                setUrlImages(experience.images)
                setLoadingExperience(false)
            })
            .catch(() => setLoadingExperience(false))
    }


    function getDetailImages(experience) {
        let imagesUrl = []
        experience?.images?.map(async image => {
            await getUrlImg(rentUser.rentId, experience.name, 'detail', image)
                .then(urlDetailImage => {
                    imagesUrl.push(urlDetailImage)
                    setUrlImages([...imagesUrl])
                })
        })
    }

    function handleArchiveExperience() {
        setLoadingArchivingExperience(true)
        experience.archived = !experience.archived
        const rentUpdateExperienceInfoCommand = httpsCallable(functions, 'rentUpdateExperienceInfoCommand')
        const request = {
            rentId: rentUser.rentId,
            experienceId: experienceId,
            newExperience: experience
        }
        rentUpdateExperienceInfoCommand(request)
            .then((response) => {
                setExperience(response.data.experience)
                refreshExperiences()
                setLoadingArchivingExperience(false)
            }).catch(() => setLoadingArchivingExperience(false))
    }

    function handleDuplicateItem() {
        setLoadingDuplicateExperience(true)
        const request = {
            rentId: rentUser.rentId,
            experience: experience
        }
        const rentDuplicateExperienceCommand = httpsCallable(functions, 'rentDuplicateExperienceCommand')
        rentDuplicateExperienceCommand(request)
            .then((response) => {
                const experience = response.data.experience
                alert('Nuova esperienza creata con codice ' + experience.experienceId)
                refreshExperiences()
                setLoadingDuplicateExperience(false)
            })
            .catch(() => setLoadingDuplicateExperience(false))
    }

    const archivedContainerStyle = {
        ...pageStyle.containerDisplayInfo,
        borderLeft: '2px solid ' + ARCHIVED_COLOR,
        borderBottom: '2px solid ' + ARCHIVED_COLOR,
        paddingRight: 5,
        justifySelf: 'flex-start'
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingExperience ?
                <Loader absoluteFullPage={true}/> :
                <div ref={experienceDetailRef}
                     style={pageStyle.container}>
                    <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                        <div style={pageStyle.containerImages}>
                            {urlImages?.map((url, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{marginRight: 5, marginBottom: 5}}>
                                        <ExperienceImage imageUrl={url}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MyNormalText text={experience.name}
                                          bold={true}
                                          fontSize={FONT_SIZE_TITLE}/>
                            <MyNormalText text={experience.id}/>
                        </div>
                        {experience.archived ?
                            <div style={pageStyle.subSection}>
                                <div style={archivedContainerStyle}>
                                    <MyNormalText text={'Archiviato'}
                                                  bold={true}/>
                                </div>
                            </div> :
                            <></>}
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={'Difficoltà: ' + experience?.difficulty}/>
                            </div>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={'Prezzo: ' + EURO_VALUE + experience?.price}/>
                            </div>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={'Arrivo in negozio: ' + experience?.shopArrivalTime}/>
                            </div>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={'Partenza: ' + experience?.departureTime}/>
                            </div>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={'Rientro: ' + experience?.shopReturnTime}/>
                            </div>
                        </div>
                        {experience?.totalKm ?
                            <div style={pageStyle.subSection}>
                                <div style={pageStyle.containerDisplayInfo}>
                                    <MyNormalText text={'Km totali: ' + experience?.totalKm}/>
                                </div>
                            </div> :
                            <></>}
                        {experience?.elevationGain ?
                            <div style={pageStyle.subSection}>
                                <div style={pageStyle.containerDisplayInfo}>
                                    <MyNormalText text={'Dislivello: ' + experience?.elevationGain}/>
                                </div>
                            </div> :
                            <></>}
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={'Minimo n. prenotazioni: ' + experience?.minNumberBookings}/>
                            </div>
                        </div>
                        <div style={pageStyle.containerDisplayInfo}>
                            {experience?.infos?.map((info, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
                                        <MyNormalText text={info.title}
                                                      bold={true}/>
                                        <MyNormalText text={info.description}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {openOperations ?
                        <>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <MyNormalText text={'Archiviazione'}
                                              bold={true}
                                              fontSize={FONT_SIZE_TITLE}/>
                                <MainButton backgroundColor={premiumPalette.secondaryColor}
                                            loading={loadingArchivingExperience}
                                            disabled={loadingArchivingExperience}
                                            text={experience.archived ? 'Rimuovi dall\'archivio' : 'Archivia'}
                                            action={handleArchiveExperience}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <MyNormalText text={'Aggiorna informazioni'}
                                              bold={true}
                                              fontSize={FONT_SIZE_TITLE}/>
                                <UpdateExperience rentUser={rentUser}
                                                  experience={experience}
                                                  premiumPalette={premiumPalette}
                                                  refreshExperiences={refreshExperiences}
                                                  refreshExperience={getExperience}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <div style={pageStyle.flexColumn}>
                                    <MyNormalText text={'Duplica'}
                                                  bold={true}
                                                  fontSize={FONT_SIZE_TITLE}/>
                                    <MyNormalText text={'Crea un nuovo articolo identico a questo'}/>
                                </div>
                                <MainButton backgroundColor={premiumPalette.secondaryColor}
                                            loading={loadingDuplicateExperience}
                                            disabled={loadingDuplicateExperience}
                                            text={'Duplica'}
                                            action={handleDuplicateItem}/>
                            </div>
                        </> :
                        <></>}
                </div>
            }
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'scroll'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        paddingBottom: 20,
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translate(-50%, 0)'
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {},
    containerImages: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start'
    },
    margin10: {
        marginTop: 10
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerDisplayInfo: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        marginRight: 40,
        borderLeft: '1px solid black',
        alignSelf: 'flex-start',
        gap: 10
    }
}

export default ExperienceDetail
