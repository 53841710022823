import React, {useEffect, useState} from "react";
import {getUrlImg} from "../../../global/firebaseStorageRepo";
import MyNormalText from "../../commons/myNormalText";
import {ARCHIVED_COLOR, EURO_VALUE, GLOBAL_BORDER_RADIUS} from "../../../costants";
import {getHoverStyle} from "../../../global/style/globalButtonHoverShadowStyle";
import ExperienceDetail from "./experienceDetail";

const DEFAULT_WIDTH = 180
const BORDERS_RADIUS = GLOBAL_BORDER_RADIUS

function ExperienceBox({
                           rentUser,
                           experience,
                           fontSizeTitle,
                           itemNameOverflow,
                           fontSizeInfo,
                           premiumPalette,
                           openOperations,
                           refreshExperiences,
                           blockOpenDetail,
                           width,
                           backgroundColor,
                           imageHeight
                       }) {

    const rentId = rentUser.rentId
    const experienceId = experience?.experienceId
    const imageName = experience?.cover
    const experienceName = experience?.name
    const difficulty = experience?.difficulty
    const price = experience?.price
    const shopReturnTime = experience?.shopReturnTime
    const departureTime = experience?.departureTime
    const itemFontSizeTitle = fontSizeTitle ? fontSizeTitle : 16
    const itemFontSizeInfo = fontSizeInfo ? fontSizeInfo : 14

    const [openExperienceDetail, setOpenExperienceDetail] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const [urlCover, setUrlCover] = useState(null)
    const [loadingImage, setLoadingImage] = useState(true)

    useEffect(() => {
        if (!localStorage.getItem(experienceName)) {
            getUrlImg(rentId, experienceName, 'cover', imageName)
                .then(url => {
                    localStorage.setItem(experienceName, url)
                    setUrlCover(url)
                })
        } else {
            const imageUrl = localStorage.getItem(experienceName)
            setUrlCover(imageUrl)
        }
    }, [])

    function handleOpenItemDetail() {
        setOpenExperienceDetail(true)
    }

    const imageStyle = {
        ...pageStyle.image,
        opacity: loadingImage ? 0 : 1
    }

    const containerItemImageStyle = {
        ...pageStyle.containerItemImage,
        height: imageHeight ? imageHeight : 150
    }

    const containerInfoItemStyle = {
        ...pageStyle.containerInfoItem,
        backgroundColor: backgroundColor ? backgroundColor : premiumPalette.primaryColor
    }

    const containerStyle = {
        ...pageStyle.container,
        cursor: blockOpenDetail ? undefined : 'pointer',
        width: width ? width : DEFAULT_WIDTH,
        transform: (isHovered && !blockOpenDetail) ? 'scale(1.02)' : undefined
    }

    return (
        <>
            <div key={experienceId}
                 onClick={handleOpenItemDetail}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
                 style={containerStyle}>
                {blockOpenDetail ?
                    <></> :
                    <div style={getHoverStyle(isHovered, BORDERS_RADIUS)}/>}
                <div style={containerItemImageStyle}>
                    <img style={imageStyle}
                         onLoad={() => setLoadingImage(false)}
                         src={urlCover}
                         alt={'JPG Image Cover'}/>
                    <div style={pageStyle.absoluteImageContainer}>
                        {experience.archived ?
                            <div style={pageStyle.containerHighlighted}>
                                <MyNormalText text={'Archiviato'}
                                              fontSize={14}/>
                            </div> :
                            <></>}
                    </div>
                </div>
                <div style={containerInfoItemStyle}>
                    <MyNormalText text={experienceName}
                                  fontSize={itemFontSizeTitle}
                                  textOverflow={itemNameOverflow}
                                  bold={true}/>
                    <MyNormalText text={'Difficoltà: ' + difficulty}
                                  fontSize={itemFontSizeInfo}
                                  textOverflow={itemNameOverflow}/>
                    <MyNormalText text={'Prezzo: ' + EURO_VALUE + price}
                                  fontSize={itemFontSizeInfo}
                                  textOverflow={itemNameOverflow}/>
                    <MyNormalText text={'Partenza: ' + departureTime}
                                  fontSize={itemFontSizeInfo}
                                  textOverflow={itemNameOverflow}/>
                    <MyNormalText text={'Rientro: ' + shopReturnTime}
                                  fontSize={itemFontSizeInfo}
                                  textOverflow={itemNameOverflow}/>
                </div>
            </div>
            {!blockOpenDetail && openExperienceDetail ?
                <ExperienceDetail rentUser={rentUser}
                                  experienceId={experienceId}
                                  openOperations={openOperations}
                                  premiumPalette={premiumPalette}
                                  refreshExperiences={refreshExperiences}
                                  setIsOpen={setOpenExperienceDetail}/> :
                <></>}
        </>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        transition: 'transform 0.5s ease',
        maxWidth: '220px'
    },
    image: {
        borderTopLeftRadius: BORDERS_RADIUS,
        borderTopRightRadius: BORDERS_RADIUS,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'opacity 0.5s ease-in-out'
    },
    containerInfoItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        borderEndEndRadius: BORDERS_RADIUS,
        borderEndStartRadius: BORDERS_RADIUS,
        padding: 5,
        paddingTop: 10
    },
    containerItemImage: {
        width: '100%'
    },
    containerHighlighted: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: ARCHIVED_COLOR,
        borderRadius: BORDERS_RADIUS
    },
    absoluteImageContainer: {
        position: 'absolute',
        top: 0
    }
}

export default ExperienceBox
