import React, {useEffect, useState} from "react";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import Select from "react-select";
import {globalElementSelectStyle} from "../../../global/style/globalElementSelectStyle";
import {EXPERIENCE_DIFFICULTY, EXPERIENCE_TYPES, GLOBAL_BORDER_RADIUS, HIGHLIGHT_COLOR} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import MainButton from "../../commons/mainButton";
import Snackbar from "../../commons/snackBar";
import Loader from "../../commons/loader";
import DatePicker from "react-datepicker";
import {toMomentDate} from "../../../global/dates";
import {removeStringFromArray} from "../../../global/arrays";

function CreateExperiences({rentSelected, setRentSelected, rents, premiumPalette}) {

    const primaryColor = premiumPalette.primaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative
    const secondaryColor = premiumPalette.secondaryColor

    const stringInitialValue = ''
    const [cover, setCover] = useState(stringInitialValue)
    const [imageSearched, setImageSearched] = useState(null)
    const [searchField, setSearchField] = useState(stringInitialValue)
    const [name, setName] = useState(stringInitialValue)
    const [experienceDifficulty, setExperienceDifficulty] = useState(stringInitialValue)
    const [experienceTypes, setExperienceTypes] = useState([])
    const [shopArrivalTime, setShopArrivalTime] = useState(stringInitialValue)
    const [departureTime, setDepartureTime] = useState(stringInitialValue)
    const [shopReturnTime, setShopReturnTime] = useState(stringInitialValue)
    const [totalKm, setTotalKm] = useState(stringInitialValue)
    const [elevationGain, setElevationGain] = useState(stringInitialValue)
    const [minNumberBookings, setMinNumberBookings] = useState(null)
    const [rangesAvailability, setRangesAvailability] = useState([{from: null, to: null}])
    const [debouncedSearchBikeField, setDebouncedSearchBikeField] = useState(stringInitialValue)
    const [archived, setArchived] = useState(false)
    const [itemsIncluded, setItemsIncluded] = useState(false)
    const [italianTitleDescription, setItalianTitleDescription] = useState(stringInitialValue)
    const [italianDescription, setItalianDescription] = useState(stringInitialValue)
    const [englishTitleDescription, setEnglishTitleDescription] = useState(stringInitialValue)
    const [englishDescription, setEnglishDescription] = useState(stringInitialValue)
    const [germanTitleDescription, setGermanTitleDescription] = useState(stringInitialValue)
    const [germanDescription, setGermanDescription] = useState(stringInitialValue)
    const [price, setPrice] = useState(1)
    const [amount, setAmount] = useState(1)
    const [callCount, setCallCount] = useState(0)
    const [cantSaveTickets, setCantSaveTickets] = useState(false)
    const [ticketSaved, setTicketSaved] = useState(false)
    const [imageNotFound, setImageNotFound] = useState(false)
    const [errorDuringSearch, setErrorDuringSearch] = useState(false)
    const [loadingSearchBike, setLoadingSearchBike] = useState(false)
    const [loading, setLoading] = useState(false)

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 80
    }

    useEffect(() => {
        if (searchField === stringInitialValue) {
            return
        }

        const timer = setTimeout(() => {
            setDebouncedSearchBikeField(searchField)
        }, 1500)
        return () => clearTimeout(timer)
    }, [searchField])

    useEffect(() => {
        if (debouncedSearchBikeField) {
            setLoadingSearchBike(true)
            fetchBikeImage(debouncedSearchBikeField)
        }
    }, [debouncedSearchBikeField])

    const fetchBikeImage = async () => {
        const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
        const GOOGLE_SEARCH_ENGINE_ID = process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID
        const query = encodeURIComponent(searchField)

        const url = `https://www.googleapis.com/customsearch/v1?q=${query}&searchType=image&fileType=jpg&key=${API_KEY}&cx=${GOOGLE_SEARCH_ENGINE_ID}`

        try {
            const response = await fetch(url)
            const data = await response.json()
            if (data.items && data.items.length > 0) {
                const imageUrl = data.items[0].link
                setImageSearched(imageUrl)
                convertToBase64(imageUrl)
            } else {
                setImageNotFound(true)
            }
            setLoadingSearchBike(false)
        } catch (error) {
            setLoadingSearchBike(false)
            alert('Errore nella ricerca.')
        }
    }

    const convertToBase64 = async (url) => {
        try {
            const response = await fetch(url)
            const blob = await response.blob()
            const reader = new FileReader()
            reader.onloadend = () => setCover(reader.result)
            reader.readAsDataURL(blob)
        } catch (error) {
            setErrorDuringSearch(true)
        }
    }

    function handleFileChange(event) {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setCover(reader.result)
                setImageSearched(reader.result)
            }
            reader.readAsDataURL(file)
        }
    }

    function handleSaveMultipleTickets() {
        const atLeastOneValueMissing = cover === stringInitialValue || name === stringInitialValue || !price || !minNumberBookings ||
            englishDescription === stringInitialValue || italianDescription === stringInitialValue || germanDescription === stringInitialValue ||
            experienceDifficulty === stringInitialValue || shopArrivalTime === stringInitialValue || departureTime === stringInitialValue || shopReturnTime === stringInitialValue ||
            italianTitleDescription === stringInitialValue || englishTitleDescription === stringInitialValue || germanTitleDescription === stringInitialValue

        if (atLeastOneValueMissing) {
            setCantSaveTickets(true)
            return
        }

        setLoading(true)
        const request = {
            rentId: rentSelected.rentId,
            coverJpg: cover,
            experience: {
                isAvailable: true,
                images: ["first.jpg"],
                rent: {
                    rentId: rentSelected.rentId,
                    cover: "cover.jpg",
                    city: rentSelected.city,
                    street: rentSelected.street,
                    name: rentSelected.name,
                    categories: rentSelected.categories,
                    type: rentSelected.type,
                    email: rentSelected.email
                },
                cover: "cover.jpg",
                archived: archived,
                itemsIncluded: itemsIncluded,
                name: name,
                difficulty: experienceDifficulty,
                types: experienceTypes,
                departureTime: departureTime,
                shopReturnTime: shopReturnTime,
                elevationGain: elevationGain,
                totalKm: totalKm,
                shopArrivalTime: shopArrivalTime,
                minNumberBookings: minNumberBookings,
                price: price,
                rangesAvailability: rangesAvailability,
                infos: [
                    {
                        title: italianTitleDescription,
                        description: italianDescription,
                        language: "it"
                    },
                    {
                        title: englishTitleDescription,
                        description: englishDescription,
                        language: "en"
                    },
                    {
                        title: germanTitleDescription,
                        description: germanDescription,
                        language: "de"
                    }
                ]
            }
        }

        const sleep = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        const fetchSequentially = async () => {
            for (let i = 0; i < amount; i++) {
                setCallCount(prevCount => prevCount + 1)
                try {
                    const rentSuperUserAdminCreateExperienceCommand = httpsCallable(functions, 'rentSuperUserAdminCreateExperienceCommand')
                    await rentSuperUserAdminCreateExperienceCommand(request)
                    await sleep(150)
                } catch (error) {
                    alert('Ooops! Qualcosa sembra essere andato storto.')
                }
            }
            setCallCount(0)
        }

        fetchSequentially()
            .then(() => {
                setTicketSaved(true)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }

    function handleChangeAmount(value) {
        const newAmount = amount + value
        if (newAmount < 1 || newAmount > 20) {
            return
        }
        setAmount(newAmount)
    }


    function getExperienceDifficulties() {
        return Object.keys(EXPERIENCE_DIFFICULTY).map(size => ({value: size, label: size}))
    }

    const addDateRange = () => {
        setRangesAvailability([...rangesAvailability, {from: null, to: null}])
    }

    const removeDateRange = (index) => {
        setRangesAvailability(rangesAvailability.filter((_, i) => i !== index))
    }

    const updateDateRange = (index, field, date) => {
        const newRanges = [...rangesAvailability]
        newRanges[index][field] = date
        setRangesAvailability(newRanges)
    }

    function handleSelectExperienceType(type) {
        if (experienceTypes.includes(type)) {
            setExperienceTypes([...removeStringFromArray(experienceTypes, type)])
        } else {
            experienceTypes.push(type)
            setExperienceTypes([...experienceTypes])
        }
    }

    let inputStyle = {...globalElementInputStyle.customInput, width: 250};
    return (
        <div style={pageStyle.container}>
            {loading ?
                <Loader absoluteFullPage={true}
                        absoluteFullPageText={callCount + ' su ' + amount}/> :
                <></>}
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Noleggio'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={pageStyle.nameRentsContainer}>
                        {rents.map(rent => {
                            return (
                                <div key={rent.rentId}>
                                    <MainButton text={rent.name}
                                                action={() => setRentSelected(rent)}
                                                backgroundColor={rentSelected.rentId === rent.rentId ? secondaryColor : primaryColorFirstAlternative}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Immagine'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                            <MyNormalText text={'Cerca la foto'}/>
                            <input style={globalElementInputStyle.customInput}
                                   type={'text'}
                                   value={searchField}
                                   placeholder={'Immagine *'}
                                   onChange={(e) => setSearchField(e.target.value)}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                            <MyNormalText text={'Oppure carica la foto a mano'}/>
                            <input type={'file'}
                                   accept={'image/jpeg'}
                                   onChange={handleFileChange}/>
                        </div>
                    </div>
                    <div>
                        {imageSearched ?
                            loadingSearchBike ?
                                <div style={pageStyle.containerLoader}>
                                    <Loader/>
                                </div> :
                                <img src={imageSearched}
                                     alt={'Immagine bici'}
                                     style={pageStyle.coverBike}/> :
                            <></>}
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Informazioni base'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div>
                        <input style={inputStyle}
                               type={'text'}
                               value={name}
                               placeholder={'Nome *'}
                               onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div>
                        <Select styles={globalElementSelectStyle}
                                placeholder={'Difficoltà *'}
                                onChange={(value) => setExperienceDifficulty(value.label)}
                                options={getExperienceDifficulties()}/>
                    </div>
                    <div>
                        <input style={inputStyle}
                               type={'text'}
                               value={shopArrivalTime}
                               placeholder={'Arrivo in negozio (09.00) *'}
                               onChange={(e) => setShopArrivalTime(e.target.value)}/>
                    </div>
                    <div>
                        <input style={inputStyle}
                               type={'text'}
                               value={departureTime}
                               placeholder={'Orario partenza (09.30) *'}
                               onChange={(e) => setDepartureTime(e.target.value)}/>
                    </div>
                    <div>
                        <input style={inputStyle}
                               type={'text'}
                               value={shopReturnTime}
                               placeholder={'Orario rientro (16.30) *'}
                               onChange={(e) => setShopReturnTime(e.target.value)}/>
                    </div>
                    <div>
                        <input style={inputStyle}
                               type={'text'}
                               value={totalKm}
                               placeholder={'Km totali (es: 3km)'}
                               onChange={(e) => setTotalKm(e.target.value)}/>
                    </div>
                    <div>
                        <input style={inputStyle}
                               type={'text'}
                               value={elevationGain}
                               placeholder={'Dislivello (es: 500m)'}
                               onChange={(e) => setElevationGain(e.target.value)}/>
                    </div>
                    <div>
                        <input style={inputStyle}
                               type={'number'}
                               value={minNumberBookings}
                               placeholder={'Minimo n. prenotazioni *'}
                               onChange={(e) => setMinNumberBookings(e.target.value)}/>
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Tipologie'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    {EXPERIENCE_TYPES.map(type => {
                        return (
                            <div key={type}>
                                <MainButton text={type}
                                            action={() => handleSelectExperienceType(type)}
                                            backgroundColor={experienceTypes.includes(type) ? secondaryColor : primaryColorFirstAlternative}
                                            borderRadius={50}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 10, display: 'flex', flexDirection: 'column'}}>
                    <MyNormalText text={'Periodo validità (inserirne almeno uno) *'}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText text={'inserirne almeno uno'}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={pageStyle.section}>
                        {rangesAvailability.map((range, index) => (
                            <div key={index}
                                 style={{display: 'flex', alignItems: 'center', gap: 20}}>
                                <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                    <MyNormalText text={'Dal'}/>
                                    <DatePicker selected={range.from ? toMomentDate(range.from).toDate() : null}
                                                onChange={(date) => updateDateRange(index, 'from', date)}
                                                showMonthYearDropdown={false}
                                                className={'custom-input'}
                                                dateFormat={'dd-MM-yyyy'}
                                                locale={'it'}
                                                placeholderText={'Data inizio'}/>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                    <MyNormalText text={'Al'}/>
                                    <DatePicker selected={range.to ? toMomentDate(range.to).toDate() : null}
                                                onChange={(date) => updateDateRange(index, 'to', date)}
                                                showMonthYearDropdown={false}
                                                className={'custom-input'}
                                                dateFormat={'dd-MM-yyyy'}
                                                locale={'it'}
                                                placeholderText={'Data fine'}/>
                                </div>
                                {rangesAvailability.length > 1 ?
                                    <div>
                                        <MainButton action={() => removeDateRange(index)}
                                                    text={'Rimuovi'}
                                                    backgroundColor={HIGHLIGHT_COLOR}/>
                                    </div> :
                                    <></>}
                            </div>
                        ))}
                        <MainButton action={addDateRange}
                                    text={'Aggiungi'}
                                    backgroundColor={secondaryColor}/>
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Descrizione italiano'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <input style={globalElementInputStyle.customInput}
                           type={'text'}
                           value={italianTitleDescription}
                           placeholder={'Titolo italiano *'}
                           onChange={(e) => setItalianTitleDescription(e.target.value)}/>
                    <textarea style={textAreaStyle}
                              value={italianDescription}
                              placeholder={'Inserisci la descrizione *'}
                              onChange={(event) => setItalianDescription(event.target.value)}
                              rows={italianDescription.split('\n').length}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Descrizione inglese'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <input style={globalElementInputStyle.customInput}
                           type={'text'}
                           value={englishTitleDescription}
                           placeholder={'Titolo inglese *'}
                           onChange={(e) => setEnglishTitleDescription(e.target.value)}/>
                    <textarea style={textAreaStyle}
                              value={englishDescription}
                              placeholder={'Inserisci la descrizione *'}
                              onChange={(event) => setEnglishDescription(event.target.value)}
                              rows={englishDescription.split('\n').length}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Descrizione tedesco'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <input style={globalElementInputStyle.customInput}
                           type={'text'}
                           value={germanTitleDescription}
                           placeholder={'Titolo tedesco *'}
                           onChange={(e) => setGermanTitleDescription(e.target.value)}/>
                    <textarea style={textAreaStyle}
                              value={germanDescription}
                              placeholder={'Inserisci la descrizione *'}
                              onChange={(event) => setGermanDescription(event.target.value)}
                              rows={germanDescription.split('\n').length}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Prezzo *'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <input style={globalElementInputStyle.customInput}
                           type={'number'}
                           value={price}
                           placeholder={'Prezzo'}
                           onChange={(e) => {
                               const price = e.target.value
                               if (price) {
                                   setPrice(parseInt(price))
                               }
                           }}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Articoli inclusi'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <MyNormalText text={'Gli articoli sono inclusi nell\'esperienza?'}/>
                    <input type="checkbox"
                           style={{width: 20, height: 20}}
                           checked={itemsIncluded}
                           onChange={() => setItemsIncluded(!itemsIncluded)}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Archivio'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <MyNormalText text={'Creare il ticket archiviato?'}/>
                    <input type="checkbox"
                           style={{width: 20, height: 20}}
                           checked={archived}
                           onChange={() => setArchived(!archived)}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Quantità'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <div style={{display: 'flex', alignItems: 'center', width: 100, gap: 20}}>
                        <MainButton text={'-'}
                                    action={() => handleChangeAmount(-1)}
                                    backgroundColor={primaryColorFirstAlternative}/>
                        <MyNormalText text={amount}/>
                        <MainButton text={'+'}
                                    action={() => handleChangeAmount(+1)}
                                    backgroundColor={primaryColorFirstAlternative}/>
                    </div>
                </div>
            </div>
            <div>
                <MainButton text={'Salva'}
                            action={handleSaveMultipleTickets}
                            disabled={loading}
                            backgroundColor={secondaryColor}/>
            </div>
            {cantSaveTickets ?
                <Snackbar message={'Assicurati di aver compilato tutti i campi richiesti'}
                          duration={4000}
                          isWarning={true}
                          onClose={() => setCantSaveTickets(false)}/> :
                <></>}
            {ticketSaved ?
                <Snackbar message={'Censimento completato con successo'}
                          duration={4000}
                          isOk={true}
                          onClose={() => setTicketSaved(false)}/> :
                <></>}
            {imageNotFound ?
                <Snackbar message={'Nessuna immagine trovata'}
                          duration={4000}
                          isWarning={true}
                          onClose={() => setImageNotFound(false)}/> :
                <></>}
            {errorDuringSearch ?
                <Snackbar message={'Sembra esserci un problema con la foto trovata. Prego cambiare ricerca.'}
                          duration={4000}
                          isAlert={true}
                          onClose={() => setErrorDuringSearch(false)}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    innerContainer: {
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    },
    nameRentsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    },
    containerLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        width: 300
    },
    coverBike: {
        width: 300,
        height: 'auto',
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default CreateExperiences
