import React from "react";
import Languages from "./languages";
import MyMenu from "../menu/myMenu";
import {useLocation, useNavigate} from "react-router-dom";
import {websitePages} from "../../../costants";

function Header({languageState, isMobileView}) {

    const navigate = useNavigate()
    const location = useLocation()

    if (location.pathname.includes(websitePages.DASHBOARD)) {
        return null
    }

    function getLogoLocemSolutions() {
        return (
            <div onClick={() => navigate(websitePages.HOME)}
                 style={pageStyle.container}>
                {!isMobileView ?
                    <>
                        <img src={require("../../../imgs/home/logo_scritta.png")}
                             alt="Logo LoceM"
                             style={pageStyle.logo}/>
                        <div style={pageStyle.point}/>
                    </> :
                    <></>}
                <img src={require("../../../imgs/booking/logo.png")}
                     alt="Logo"
                     style={{width: 40}}/>
            </div>
        )
    }

    return (
        <div style={pageStyle.header}>
            {isMobileView ?
                <Languages languageState={languageState}/> :
                <></>}
            {getLogoLocemSolutions()}
            <MyMenu languageState={languageState}
                    navigate={navigate}
                    location={location}
                    isMobileView={isMobileView}/>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        cursor: 'pointer'
    },
    header: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(4px)',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 2,
        padding: 20
    },
    point: {
        width: 5,
        height: 5,
        background: 'black',
        borderRadius: 20
    },
    logo: {
        height: 45,
        width: 120
    }
}

export default Header
