import React, {useState} from "react";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import Select from "react-select";
import {globalElementSelectStyle} from "../../../global/style/globalElementSelectStyle";
import {EXPERIENCE_DIFFICULTY, EXPERIENCE_TYPES, GLOBAL_BORDER_RADIUS, HIGHLIGHT_COLOR} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import MainButton from "../../commons/mainButton";
import DatePicker, {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import "../../../css/myDatePicker.css";
import {fromMomentDate, toMomentDate} from "../../../global/dates";
import {removeStringFromArray} from "../../../global/arrays";

registerLocale("it", it);

function UpdateExperience({rentUser, experience, refreshExperiences, refreshExperience, premiumPalette}) {

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const [price, setPrice] = useState(experience.price)
    const [difficulty, setDifficulty] = useState(experience.difficulty)
    const [types, setTypes] = useState(experience.types)
    const [itemsIncluded, setItemsIncluded] = useState(experience.itemsIncluded)
    const [shopReturnTime, setShopReturnTime] = useState(experience.shopReturnTime)
    const [shopArrivalTime, setShopArrivalTime] = useState(experience.shopArrivalTime)
    const [departureTime, setDepartureTime] = useState(experience.departureTime)
    const [totalKm, setTotalKm] = useState(experience.totalKm)
    const [elevationGain, setElevationGain] = useState(experience.elevationGain)
    const [minNumberBookings, setMinNumberBookings] = useState(experience.minNumberBookings)
    const [rangesAvailability, setRangesAvailability] = useState(experience.rangesAvailability ? experience.rangesAvailability : [{from: null, to: null}])
    const [italianTitleDescription, setItalianTitleDescription] = useState(experience.infos.find(info => info.language === 'it').title)
    const [italianDescription, setItalianDescription] = useState(experience.infos.find(info => info.language === 'it').description)
    const [englishTitleDescription, setEnglishTitleDescription] = useState(experience.infos.find(info => info.language === 'en').title)
    const [englishDescription, setEnglishDescription] = useState(experience.infos.find(info => info.language === 'en').description)
    const [germanTitleDescription, setGermanTitleDescription] = useState(experience.infos.find(info => info.language === 'de').title)
    const [germanDescription, setGermanDescription] = useState(experience.infos.find(info => info.language === 'de').description)
    const [loading, setLoading] = useState(false)

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 160
    }

    function handleUpdateItem() {
        const atLeastOneValueMissing = !difficulty || !price || !shopArrivalTime || !shopReturnTime ||
            !departureTime || !italianDescription || !englishDescription || !germanDescription || (!rangesAvailability[0].from && !rangesAvailability[0].to)
        if (atLeastOneValueMissing) {
            alert('Attenzione, non hai compilato tutti i campi')
            return
        }

        setLoading(true)
        const experienceRequest = {
            difficulty: difficulty,
            price: price,
            types: types,
            itemsIncluded: itemsIncluded,
            shopArrivalTime: shopArrivalTime,
            shopReturnTime: shopReturnTime,
            departureTime: departureTime,
            totalKm: totalKm,
            elevationGain: elevationGain,
            minNumberBookings: minNumberBookings,
            rangesAvailability: rangesAvailability,
            infos: [
                {
                    title: italianTitleDescription,
                    description: italianDescription,
                    language: "it"
                },
                {
                    title: englishTitleDescription,
                    description: englishDescription,
                    language: "en"
                },
                {
                    title: germanTitleDescription,
                    description: germanDescription,
                    language: "de"
                }
            ]
        }

        const request = {
            rentId: rentUser.rentId,
            experienceId: experience.experienceId,
            newExperience: {...experience, ...experienceRequest}
        }
        const rentUpdateExperienceInfoCommand = httpsCallable(functions, 'rentUpdateExperienceInfoCommand')
        rentUpdateExperienceInfoCommand(request)
            .then(() => {
                setLoading(false)
                refreshExperiences()
                refreshExperience()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    function getDifficulties() {
        return Object.keys(EXPERIENCE_DIFFICULTY).map(difficulty => ({value: difficulty, label: difficulty}))
    }

    function handleSelectExperienceType(type) {
        if (types.includes(type)) {
            setTypes([...removeStringFromArray(types, type)])
        } else {
            types.push(type)
            setTypes([...types])
        }
    }

    const addDateRange = () => {
        setRangesAvailability([...rangesAvailability, {from: null, to: null}])
    }

    const removeDateRange = (index) => {
        setRangesAvailability(rangesAvailability.filter((_, i) => i !== index))
    }

    const updateDateRange = (index, field, date) => {
        const newRanges = [...rangesAvailability]
        newRanges[index][field] = date
        setRangesAvailability(newRanges)
    }

    return (
        <div style={pageStyle.container}>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={pageStyle.section}>
                    <div>
                        <MyNormalText text={'Prezzo'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={price}
                               placeholder={'Difficoltà'}
                               onChange={(e) => setPrice(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Difficoltà'}/>
                        <Select styles={globalElementSelectStyle}
                                placeholder={difficulty}
                                onChange={(value) => setDifficulty(value.label)}
                                options={getDifficulties()}/>
                    </div>
                    <div>
                        <MyNormalText text={'Arrivo in negozio'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={shopArrivalTime}
                               placeholder={'Arrivo in negozio'}
                               onChange={(e) => setShopArrivalTime(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Orario partenza'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={departureTime}
                               placeholder={'Orario partenza'}
                               onChange={(e) => setDepartureTime(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Orario rientro'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={shopReturnTime}
                               placeholder={'Orario rientro'}
                               onChange={(e) => setShopReturnTime(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Km totali'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={totalKm}
                               placeholder={'Km totali'}
                               onChange={(e) => setTotalKm(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Dislivello'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={elevationGain}
                               placeholder={'Dislivello'}
                               onChange={(e) => setElevationGain(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Minimo n. prenotazioni'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={minNumberBookings}
                               placeholder={'Minimo n. prenotazioni'}
                               onChange={(e) => setMinNumberBookings(e.target.value)}/>
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Articoli inclusi'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <input type="checkbox"
                           style={{width: 20, height: 20}}
                           checked={itemsIncluded}
                           onChange={() => setItemsIncluded(!itemsIncluded)}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Periodo validità'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    {rangesAvailability.map((range, index) => (
                        <div key={index}
                             style={{display: 'flex', alignItems: 'center', gap: 20}}>
                            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                <MyNormalText text={'Dal'}/>
                                <DatePicker selected={range.from ? toMomentDate(range.from).toDate() : null}
                                            onChange={(date) => updateDateRange(index, 'from', fromMomentDate(toMomentDate(date)))}
                                            showMonthYearDropdown={false}
                                            className={'custom-input'}
                                            dateFormat={'dd-MM-yyyy'}
                                            locale={'it'}
                                            placeholderText={'Data inizio'}/>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                <MyNormalText text={'Al'}/>
                                <DatePicker selected={range.to ? toMomentDate(range.to).toDate() : null}
                                            onChange={(date) => updateDateRange(index, 'to', fromMomentDate(toMomentDate(date)))}
                                            showMonthYearDropdown={false}
                                            className={'custom-input'}
                                            dateFormat={'dd-MM-yyyy'}
                                            locale={'it'}
                                            placeholderText={'Data fine'}/>
                            </div>
                            {rangesAvailability.length > 1 ?
                                <div>
                                    <MainButton action={() => removeDateRange(index)}
                                                text={'Rimuovi'}
                                                backgroundColor={HIGHLIGHT_COLOR}/>
                                </div> :
                                <></>}
                        </div>
                    ))}
                    <MainButton action={addDateRange}
                                text={'Aggiungi'}
                                backgroundColor={secondaryColor}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Tipologie'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    {EXPERIENCE_TYPES.map(type => {
                        return (
                            <div key={type}>
                                <MainButton text={type}
                                            action={() => handleSelectExperienceType(type)}
                                            backgroundColor={types.includes(type) ? secondaryColor : primaryColorFirstAlternative}
                                            borderRadius={50}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Descrizioni'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <MyNormalText text={'Italiano'}
                                      fontSize={18}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={italianTitleDescription}
                               placeholder={'Descrizione titolo italiano'}
                               onChange={(e) => setItalianTitleDescription(e.target.value)}/>
                        <textarea style={textAreaStyle}
                                  value={italianDescription}
                                  placeholder={'Inserisci la descrizione'}
                                  onChange={(event) => setItalianDescription(event.target.value)}
                                  rows={italianDescription?.split('\n').length}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <MyNormalText text={'Inglese'}
                                      fontSize={18}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={englishTitleDescription}
                               placeholder={'Descrizione titolo inglese'}
                               onChange={(e) => setEnglishTitleDescription(e.target.value)}/>
                        <textarea style={textAreaStyle}
                                  value={englishDescription}
                                  placeholder={'Inserisci la descrizione'}
                                  onChange={(event) => setEnglishDescription(event.target.value)}
                                  rows={englishDescription?.split('\n').length}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <MyNormalText text={'Tedesco'}
                                      fontSize={18}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={germanTitleDescription}
                               placeholder={'Descrizione titolo tedesco'}
                               onChange={(e) => setGermanTitleDescription(e.target.value)}/>
                        <textarea style={textAreaStyle}
                                  value={germanDescription}
                                  placeholder={'Inserisci la descrizione'}
                                  onChange={(event) => setGermanDescription(event.target.value)}
                                  rows={germanDescription?.split('\n').length}/>
                    </div>
                </div>
            </div>
            <div>
                <MainButton text={'Aggiorna'}
                            action={handleUpdateItem}
                            loading={loading}
                            disabled={loading}
                            backgroundColor={secondaryColor}/>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    innerContainer: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    }
}

export default UpdateExperience
