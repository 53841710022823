import React, {useEffect, useState} from "react";
import MainButton from "../../commons/mainButton";
import {signInWithEmailAndPassword, signOut} from 'firebase/auth';
import {auth, functions} from "../../../firebase";
import {GLOBAL_BORDER_RADIUS, HIGHLIGHT_COLOR} from "../../../costants";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {httpsCallable} from "firebase/functions";
import MyNormalText from "../../commons/myNormalText";
import {validateAndSetPassword} from "../../../global/password";
import Snackbar from "../../commons/snackBar";
import Loader from "../../commons/loader";
import DatePicker from "react-datepicker";
import {fromMomentDate, toMomentDate} from "../../../global/dates";

function Profile({rentUser, reloadUser, switchProfile, premiumPalette}) {

    const refund = rentUser.refund
    const rentInfo = rentUser.rentInfo
    const opening = rentUser.opening

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const [discounts, setDiscounts] = useState([])
    const [closedDates, setClosedDates] = useState([])
    const [closedFromDate, setClosedFromDate] = useState(null)
    const [closedToDate, setClosedToDate] = useState(null)
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordValidated, setNewPasswordValidated] = useState(false)
    const [passwordNotValid, setPasswordNotValid] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)
    const [loadingUpdateUser, setLoadingUpdateUser] = useState(false)
    const [loadingChangePassword, setChangePassword] = useState(false)
    const [loadingChangeProfile, setLoadingChangeProfile] = useState(false)
    const [loadingClosedDate, setLoadingClosedDate] = useState(false)

    async function logout() {
        await signOut(auth)
    }

    useEffect(() => {
        const getRentAppDiscounts = httpsCallable(functions, 'getRentAppDiscounts')
        getRentAppDiscounts({rentId: rentUser.rentId})
            .then(async (response) => {
                setDiscounts(response.data.discounts)
            })
            .catch((error) => {
                alert(error.message)
            })
    }, [])

    useEffect(() => {
        fetchUserClosedDates()
    }, [])

    function fetchUserClosedDates() {
        setLoadingClosedDate(true)
        const rentUserClosedDatesView = httpsCallable(functions, 'rentUserClosedDatesView')
        rentUserClosedDatesView({rentId: rentUser.rentId})
            .then(response => {
                setClosedDates(response.data.closedDates)
                setLoadingClosedDate(false)
            })
            .catch(() => setLoadingClosedDate(false))
    }

    function handleOpenCloseRent() {
        if (!closedFromDate || !closedToDate) {
            return
        }

        setLoadingUpdateUser(true)
        const rentUpdateClosedDatesCommand = httpsCallable(functions, 'rentUpdateClosedDatesCommand')
        const request = {
            rentId: rentUser.rentId,
            from: fromMomentDate(toMomentDate(closedFromDate)),
            to: fromMomentDate(toMomentDate(closedToDate))
        }
        rentUpdateClosedDatesCommand(request)
            .then(() => {
                fetchUserClosedDates()
                setLoadingUpdateUser(false)
            })
            .catch((error) => {
                alert(error)
                setLoadingUpdateUser(false)
            })
    }

    const handleChangePassword = () => {
        if (!newPasswordValidated) {
            setPasswordNotValid(true)
            return
        }

        setChangePassword(true)
        const changePassword = httpsCallable(functions, 'updateUser')
        changePassword({user: {uid: rentUser.rentId, password: newPassword}})
            .then(async (result) => {
                setChangePassword(false)
                if (result.data.message === 'OK') {
                    signInWithEmailAndPassword(auth, rentUser.email, newPassword)
                        .then(() => {
                            setPasswordChanged(true)
                            setNewPassword("")
                            setChangePassword(false)
                        })
                        .catch(() => setChangePassword(false))
                }
            }).catch(() => setChangePassword(false))
    }

    function handleInsertPassword(e) {
        validateAndSetPassword(e.target.value, setNewPassword, setNewPasswordValidated)
    }

    function getMinutes(minutes) {
        if (minutes === '0') {
            return '.00'
        }
        return '.' + minutes
    }

    function handleSwitchProfile(rentIdTarget) {
        if (rentIdTarget === rentUser.rentId) {
            return
        }

        setLoadingChangeProfile(true)
        switchProfile(rentIdTarget)
            .then(() => setLoadingChangeProfile(false))
            .catch(() => setLoadingChangeProfile(false))
    }

    function handleSelectClosedFromDate(date) {
        setClosedFromDate(date)
        setClosedToDate(date)
    }

    function handleSelectClosedToDate(date) {
        if (toMomentDate(date).isBefore(toMomentDate(closedFromDate))) {
            setClosedFromDate(date)
            setClosedToDate(date)
            return
        }
        if (closedFromDate) {
            setClosedToDate(date)
            return
        }
        setClosedFromDate(date)
        setClosedToDate(date)
    }

    function handleDeleteClosedDate(closedDate) {
        setLoadingClosedDate(true)
        const request = {
            rentId: rentUser.rentId,
            closedDateId: closedDate.closedDateId
        }
        const getRentAppDiscounts = httpsCallable(functions, 'rentRemoveClosedDateCommand')
        getRentAppDiscounts(request)
            .then(() => {
                fetchUserClosedDates()
                setLoadingClosedDate(false)
            })
            .catch(() => setLoadingClosedDate(false))
    }

    function getClosedDates() {
        if (closedDates.length === 0) {
            return <MyNormalText text={'-'}/>
        }

        return closedDates.map((closedDate, index) => {
            return (
                <div key={index.toString()}
                     style={{display: 'flex', gap: 10}}>
                    <MyNormalText text={'●'}/>
                    <MyNormalText text={closedDate.closedDates.from}/>
                    <MyNormalText text={'-'}/>
                    <MyNormalText text={closedDate.closedDates.to}/>
                    <div style={{width: 80}}>
                        <MainButton text={'Cancella'}
                                    action={() => handleDeleteClosedDate(closedDate)}
                                    fontSize={14}
                                    disabled={loadingClosedDate}
                                    backgroundColor={HIGHLIGHT_COLOR}
                                    height={25}/>
                    </div>
                </div>
            )
        })
    }

    return (
        <div style={pageStyle.container}>
            {loadingChangeProfile ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <div style={pageStyle.section}>
                <div style={pageStyle.subSection}>
                    <MyNormalText text={'Negozio'}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText text={rentUser.email}/>
                    <MyNormalText text={rentInfo.city + ', ' + rentInfo.street}/>
                </div>
            </div>
            {rentUser.contacts ?
                <div style={pageStyle.section}>
                    <div style={pageStyle.subSection}>
                        <MyNormalText text={'Contatti'}
                                      bold={true}
                                      fontSize={18}/>
                        {rentUser.contacts.email ?
                            <MyNormalText text={'Email: ' + rentUser.contacts.email}/> :
                            <></>}
                        {rentUser.contacts.phoneNumber ?
                            <MyNormalText text={'Telefono: ' + rentUser.contacts.phoneNumber}/> :
                            <></>}
                    </div>
                </div> :
                <></>}
            <div style={pageStyle.section}>
                <div style={pageStyle.subSection}>
                    <MyNormalText text={'Orari apertura'}
                                  bold={true}
                                  fontSize={18}/>
                    <div>
                        <MyNormalText text={'Lunedì: '}/>
                        {opening.monday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.monday.from.hour + getMinutes(opening.monday.from.minute) + ' - ' + opening.monday.to.hour + getMinutes(opening.monday.to.minute)}/>}
                    </div>
                    <div>
                        <MyNormalText text={'Martedì: '}/>
                        {opening.tuesday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.tuesday.from.hour + getMinutes(opening.tuesday.from.minute) + ' - ' + opening.tuesday.to.hour + getMinutes(opening.tuesday.to.minute)}/>}
                    </div>
                    <div>
                        <MyNormalText text={'Mercoledì: '}/>
                        {opening.wednesday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.wednesday.from.hour + getMinutes(opening.wednesday.from.minute) + ' - ' + opening.wednesday.to.hour + getMinutes(opening.wednesday.to.minute)}/>}
                    </div>
                    <div>
                        <MyNormalText text={'Giovedì: '}/>
                        {opening.thursday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.thursday.from.hour + getMinutes(opening.thursday.from.minute) + ' - ' + opening.thursday.to.hour + getMinutes(opening.thursday.to.minute)}/>}
                    </div>
                    <div>
                        <MyNormalText text={'Venerdì: '}/>
                        {opening.friday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.friday.from.hour + getMinutes(opening.friday.from.minute) + ' - ' + opening.friday.to.hour + getMinutes(opening.friday.to.minute)}/>}
                    </div>
                    <div>
                        <MyNormalText text={'Sabato: '}/>
                        {opening.saturday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.saturday.from.hour + getMinutes(opening.saturday.from.minute) + ' - ' + opening.saturday.to.hour + getMinutes(opening.saturday.to.minute)}/>}
                    </div>
                    <div>
                        <MyNormalText text={'Domenica: '}/>
                        {opening.sunday.closed ?
                            <MyNormalText text={'Chiuso: '}/> :
                            <MyNormalText
                                text={opening.sunday.from.hour + getMinutes(opening.sunday.from.minute) + ' - ' + opening.sunday.to.hour + getMinutes(opening.sunday.to.minute)}/>}
                    </div>
                </div>
            </div>
            <div style={pageStyle.section}>
                <div style={pageStyle.subSection}>
                    <MyNormalText text={'Info rimborso/annullo'}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText
                        text={'I clienti potranno richiedere un rimborso fino a ' + refund.withinDays + ' gg prima della prenotazione'}/>
                    <MyNormalText
                        text={'Trattieni il ' + refund.fee + '% del totale'}/>
                </div>
            </div>
            <div style={pageStyle.section}>
                <div style={pageStyle.subSection}>
                    <MyNormalText text={'Lista sconti'}
                                  bold={true}
                                  fontSize={18}/>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {discounts.length === 0 ?
                            <MyNormalText text={'Nessun codice sconto creato.'}/> :
                            discounts.map((discount, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{...pageStyle.discountContainer, backgroundColor: primaryColor}}>
                                        <MyNormalText text={discount.code + ' (-' + discount.percentageToApply + '%)'}/>
                                        <MyNormalText text={discount.isAvailable ? 'Attivo' : 'Disattivo'}/>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.actionSection, backgroundColor: primaryColor}}>
                <div style={{position: 'absolute', right: 20, top: 20}}>
                    {loadingClosedDate ?
                        <Loader isContained={true}/> :
                        <></>}
                </div>
                <div style={pageStyle.actionSubSection}>
                    <MyNormalText text={'Chiusura negozio'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.actionSubSection}>
                    <MyNormalText text={'Queste le date di chiusura'}/>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        {getClosedDates()}
                    </div>
                </div>
                <div style={pageStyle.actionSubSection}>
                    <MyNormalText text={'Inserisci nuovo'}/>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>
                        <DatePicker selected={closedFromDate}
                                    onChange={date => handleSelectClosedFromDate(date)}
                                    className={'custom-input'}
                                    showMonthYearDropdown={false}
                                    dateFormat={'dd/MM/yyyy'}
                                    locale={'it'}
                                    placeholderText={'Data inizio'}/>
                        <DatePicker selected={closedToDate}
                                    onChange={date => handleSelectClosedToDate(date)}
                                    className={'custom-input'}
                                    showMonthYearDropdown={false}
                                    dateFormat={'dd/MM/yyyy'}
                                    locale={'it'}
                                    placeholderText={'Data fine'}/>
                    </div>
                </div>
                <div>
                    <MainButton text={'Salva'}
                                action={handleOpenCloseRent}
                                disabled={loadingUpdateUser}
                                loading={loadingUpdateUser}
                                backgroundColor={premiumPalette.secondaryColor}/>
                </div>
            </div>
            <div style={{...pageStyle.actionSection, backgroundColor: primaryColor}}>
                <div style={pageStyle.actionSubSection}>
                    <MyNormalText text={'Cambia password'}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText text={'Deve essere lunga almeno 6 caratteri'}/>
                </div>
                <div>
                    <input style={globalElementInputStyle.customInput}
                           type="text"
                           value={newPassword}
                           placeholder={'Nuova password...'}
                           onChange={(e) => handleInsertPassword(e)}/>
                </div>
                <div>
                    <MainButton text={'Cambia password'}
                                loading={loadingChangePassword}
                                backgroundColor={premiumPalette.secondaryColor}
                                action={handleChangePassword}/>
                </div>
            </div>
            <div style={{...pageStyle.actionSection, backgroundColor: primaryColor}}>
                <div style={pageStyle.actionSubSection}>
                    <MyNormalText text={'Cambia account'}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText text={'Accedi ad un altro account collegato'}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10}}>
                    {rentUser.isMultiStoreRent ?
                        rentUser.linkedStores.map(linkedStore => {
                            return (
                                <div key={linkedStore.rentId}>
                                    <MainButton text={linkedStore.name + ' (' + linkedStore.email + ')'}
                                                action={() => handleSwitchProfile(linkedStore.rentId)}
                                                backgroundColor={linkedStore.rentId === rentUser.rentId ? secondaryColor : primaryColorFirstAlternative}/>
                                </div>
                            )
                        }) :
                        <MyNormalText text={'-'}/>}
                </div>
            </div>
            <div>
                <MainButton text={'Logout'}
                            action={logout}
                            textColor={'white'}
                            backgroundColor={'black'}/>
            </div>
            {passwordNotValid ?
                <Snackbar message={'Attenzione! La password deve essere lunga almeno 6 caratteri.'}
                          duration={4000}
                          isWarning={true}
                          onClose={() => setPasswordNotValid(false)}/> :
                <></>}
            {passwordChanged ?
                <Snackbar message={'Password cambiata con successo'}
                          duration={4000}
                          isOk={true}
                          onClose={() => setPasswordChanged(false)}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: 40
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        borderLeft: '1px solid black',
        alignSelf: 'flex-start'
    },
    subSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    actionSection: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        gap: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    actionSubSection: {
        display: 'flex',
        flexDirection: 'column'
    },
    coverImage: {
        objectFit: 'cover',
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10,
    },
    discountContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        marginRight: 5,
        marginBottom: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default Profile
