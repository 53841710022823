import React, {useState} from "react";
import {GLOBAL_BORDER_RADIUS} from "../../../costants";

function ExperienceImage({imageUrl}) {
    const [loading, setLoading] = useState(true)

    const imageStyle = {
        ...pageStyle.image,
        opacity: loading ? 0 : 1,
        transition: "opacity 0.5s ease-in-out"
    }

    return (
        <img style={imageStyle}
             src={imageUrl}
             onLoad={() => setLoading(false)}
             alt="JPG Detail"/>
    )
}

const pageStyle = {
    image: {
        borderRadius: GLOBAL_BORDER_RADIUS,
        objectFit: 'contain',
        maxWidth: 300,
        height: 'auto'
    }
}

export default ExperienceImage
