import React, {useState} from "react";
import {GLOBAL_BORDER_RADIUS, singleRentType} from "../../../costants";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import MyNormalText from "../../commons/myNormalText";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import Loader from "../../commons/loader";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {validateAndSetPassword} from "../../../global/password";

const initialValues = {
    coordinates: {
        _longitude: 11.006996683950977,
        _latitude: 45.272369381536755,
    },
    type: "NOLEGGIO BICI",
    opening: {
        monday: {from: {hour: "8", minute: "0"}, to: {hour: "19", minute: "0"}},
        tuesday: {from: {hour: "8", minute: "0"}, to: {hour: "19", minute: "0"}},
        wednesday: {from: {hour: "8", minute: "0"}, to: {hour: "19", minute: "0"}},
        thursday: {from: {hour: "8", minute: "0"}, to: {hour: "19", minute: "0"}},
        friday: {from: {hour: "8", minute: "0"}, to: {hour: "19", minute: "0"}},
        saturday: {from: {hour: "8", minute: "0"}, to: {hour: "19", minute: "0"}},
        sunday: {from: {hour: "8", minute: "30"}, to: {hour: "19", minute: "0"}},
    },
    returnTime: "18.00",
    cover: "cover.jpg",
    arrivalTime: [{time: "9.00"}],
    deposit: {total: "€0", isActive: false},
    isClosed: true,
    hasPublicAccess: true,
    isMultiStoreRent: false,
    linkedStores: [
        {
            email: "hello@prova.com",
            name: "Qua va il nome"

        }
    ],
    categories: ["E-Bike"],
    contacts: {phoneNumber: "+39123456789", email: "hello@prova.com"},
    email: "hello@prova.com",
    chatActivated: true,
    hasExperiences: false,
    refund: {withinDays: 2, fee: 30},
    city: "Isola della Scala",
    subscriptionType: "NORMAL",
    rentSlotConfiguration: [
        {description: "Full day", from: "9", to: "18", type: "FULL_DAY", slotCode: 1},
    ],
    sorting: 10,
    name: "Qua va il nome",
    street: "Via Roma 56",
    generalInfosV2: {
        german: [{info: "I numeri 1."}],
        english: [{info: "I numeri 1."}],
        italian: [{info: "I numeri 1."}],
    }
}

const validationSchema = Yup.object({
    coordinates: Yup.object({
        _longitude: Yup.number().required("Longitudine è obbligatorio"),
        _latitude: Yup.number().required("Latitudine è obbligatorio"),
    }),
    opening: Yup.object().shape({
        monday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
        thursday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
        wednesday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
        friday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
        tuesday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
        saturday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
        sunday: Yup.object().shape({
            from: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
            to: Yup.object().shape({
                hour: Yup.string().required(),
                minute: Yup.string().required(),
            }),
        }),
    }),
    deposit: Yup.object({
        total: Yup.string().required("Totale deposito is required"),
        isActive: Yup.boolean().required(),
    }),
    chatActivated: Yup.boolean().required(),
    hasExperiences: Yup.boolean().required(),
    isMultiStoreRent: Yup.boolean().required(),
    linkedStores: Yup.array().of(
        Yup.object({
            rentId: Yup.string().required("rentId is required"),
            name: Yup.string().required("name is required"),
            email: Yup.string().required("email is required")
        })
    ),
    rentSlotConfiguration: Yup.array().of(
        Yup.object({
            description: Yup.string().required("Description is required"),
            from: Yup.string().required("Start time is required"),
            to: Yup.string().required("End time is required"),
            type: Yup.string().required("Type is required"),
            slotCode: Yup.number().required("Slot code is required"),
        })
    ),
    sorting: Yup.number().required("Numero ordinamento è obbligatorio"),
    returnTime: Yup.string().required("Orario di rientro è obbligatorio"),
    name: Yup.string().required("Nome è obbligatorio"),
    city: Yup.string().required("Città è obbligatorio"),
    email: Yup.string().required("Email è obbligatorio"),
    arrivalTime: Yup.array().of(
        Yup.object({
            time: Yup.string().required("Orario di arrivo è obbligatorio"),
        })
    ),
    subscriptionType: Yup.string().required("Il tipo di iscrizione è obbligatorio"),
    contacts: Yup.object().shape({
        phoneNumber: Yup.string().required("Cellulare è obbligatorio"),
        email: Yup.string().email("Invalid email").required("Email è obbligatorio"),
    }),
    generalInfosV2: Yup.object({
        german: Yup.array().of(
            Yup.object({
                info: Yup.string().required("Info è obbligatorio"),
            })
        ),
        english: Yup.array().of(
            Yup.object({
                info: Yup.string().required("Info è obbligatorio"),
            })
        ),
        italian: Yup.array().of(
            Yup.object({
                info: Yup.string().required("Info è obbligatorio"),
            })
        ),
    })
})

function CreateRentSection({rentUser, premiumPalette}) {

    const [cover, setCover] = useState(null)
    const [password, setPassword] = useState('')
    const [passwordValidated, setPasswordValidated] = useState(false)
    const [loading, setLoading] = useState(false)

    const primaryColor = premiumPalette.primaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative
    const primaryColorFourthAlternative = premiumPalette.primaryColorFourthAlternative

    const inputStyle = {...pageStyle.input, backgroundColor: primaryColorFourthAlternative}

    function handleFileChange(e) {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setCover(reader.result)
            }
            reader.readAsDataURL(file)
        }
    }

    async function handleSubmit(values) {
        if (!passwordValidated) {
            return
        }

        setLoading(true)
        const request = {
            rentId: rentUser.rentId,
            user: {
                name: values.name,
                email: values.email,
                password: password
            },
            coverJpg: cover,
            rent: {
                ...values,
                rentInfo: {
                    name: values.name,
                    city: values.city,
                    street: values.street,
                    cover: values.cover,
                    categories: values.categories,
                    type: values.type
                }
            }
        }
        const rentSuperUserAdminCreateRentCommand = httpsCallable(functions, 'rentSuperUserAdminCreateRentCommand')
        rentSuperUserAdminCreateRentCommand(request)
            .then((response) => {
                const newRentId = response.data.rent.rentId
                if (newRentId) {
                    alert('Nuovo noleggio creato!')
                }
                setLoading(false)
            })
            .catch(() => {
                alert('Sembra ci sia stato un problema nel censire il nuovo noleggio')
                setLoading(false)
            })
    }

    function handleInsertPassword(e) {
        validateAndSetPassword(e.target.value, setPassword, setPasswordValidated)
    }

    return (
        <div>
            {loading ?
                <Loader absoluteFullPage={true}
                        absoluteFullPageText={'Attendi qualche istante'}/> :
                <></>}
            <Formik initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                {({values, isValid}) => {
                    return (
                        <Form style={pageStyle.container}>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Tipo di iscrizione'}
                                              bold={true}/>
                                <div>
                                    <MyNormalText text={'NORMAL o PREMIUM'}/>
                                </div>
                                <div>
                                    <Field name={'subscriptionType'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name={'subscriptionType'}
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Nome'}
                                              bold={true}/>
                                <div>
                                    <Field name={'name'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name="name"
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Email'}
                                              bold={true}/>
                                <div>
                                    <Field name={'email'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name="email"
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Contatti'}
                                              bold={true}/>
                                <div>
                                    <MyNormalText text={'Cellulare '}/>
                                    <Field name={'contacts.phoneNumber'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name="contacts.phoneNumber"
                                                  component={'div'}/>
                                    <div>
                                        <MyNormalText text={'Email '}/>
                                        <Field name={'contacts.email'}
                                               style={inputStyle}
                                               type="email"/>
                                        <ErrorMessage name="contacts.email"
                                                      component={'div'}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Città'}
                                              bold={true}/>
                                <div>
                                    <Field name={'city'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name="city"
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Indirizzo'}
                                              bold={true}/>
                                <div>
                                    <Field name={'street'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name="street"
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Deposito'}
                                              bold={true}/>
                                <div>
                                    <Field name={'deposit.total'}
                                           style={inputStyle}
                                           type={'text'}
                                           placeholder={'e.g., €100'}/>
                                    <ErrorMessage name={'deposit.total'}
                                                  component={'div'}/>
                                </div>
                                <div>
                                    <MyNormalText text={'Attivo? '}/>
                                    <Field name={'deposit.isActive'}
                                           style={inputStyle}
                                           type={'checkbox'}/>
                                    <ErrorMessage name={'deposit.isActive'}
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Chat attiva?'}
                                              bold={true}/>
                                <div>
                                    <Field name={'chatActivated'}
                                           style={inputStyle}
                                           type={'checkbox'}/>
                                    <ErrorMessage name={'chatActivated'}
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Vende esperienze?'}
                                              bold={true}/>
                                <div>
                                    <Field name={'hasExperiences'}
                                           style={inputStyle}
                                           type={'checkbox'}/>
                                    <ErrorMessage name={'hasExperiences'}
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Coordinate'}
                                              bold={true}/>
                                <div>
                                    <MyNormalText text={'longitudine '}/>
                                    <Field name={'coordinates._longitude'}
                                           style={inputStyle}
                                           type={'number'}/>
                                    <ErrorMessage name={"coordinates._longitude"}
                                                  component={'div'}/>
                                    <div>
                                        <MyNormalText text={'latitudine '}/>
                                        <Field name={'coordinates._latitude'}
                                               style={inputStyle}
                                               type={'number'}/>
                                        <ErrorMessage name={'coordinates._latitude'}
                                                      component={'div'}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Categorie'}
                                              bold={true}/>
                                <FieldArray name={'categories'}
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.categories.map((category, index) => (
                                                        <div key={index}>
                                                            <MyNormalText text={'Categoria n. ' + (index + 1) + ' '}/>
                                                            <Field name={`categories.${index}`}
                                                                   style={inputStyle}
                                                                   placeholder="E-Bike"/>
                                                            <ErrorMessage name={`categories.${index}`}
                                                                          component={'div'}/>
                                                            <button type={'button'}
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    style={{marginLeft: "10px"}}>
                                                                X
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button type={'button'}
                                                            onClick={() => arrayHelpers.push("")}
                                                            style={{marginTop: "10px"}}>
                                                        +
                                                    </button>
                                                </div>
                                            )}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Orari di apertura'}
                                              bold={true}/>
                                <div>
                                    {Object.keys(values.opening).map((day) => (
                                        <div key={day}
                                             style={{
                                                 ...pageStyle.subSection,
                                                 backgroundColor: primaryColorFirstAlternative
                                             }}>
                                            <MyNormalText text={day.charAt(0).toUpperCase() + day.slice(1)}
                                                          bold={true}/>
                                            <div>
                                                <MyNormalText text={'dalle ore '}/>
                                                <Field name={`opening.${day}.from.hour`}
                                                       style={inputStyle}
                                                       type={'text'}/>
                                                <ErrorMessage name={`opening.${day}.from.hour`}
                                                              component={'div'}/>
                                                <MyNormalText text={' e minuti '}/>
                                                <Field name={`opening.${day}.from.minute`}
                                                       style={inputStyle}
                                                       type={'text'}/>
                                                <ErrorMessage name={`opening.${day}.from.minute`}
                                                              component={'div'}/>
                                            </div>
                                            <MyNormalText text={' alle ore '}/>
                                            <Field name={`opening.${day}.to.hour`}
                                                   style={inputStyle}
                                                   type={'text'}/>
                                            <ErrorMessage name={`opening.${day}.to.hour`}
                                                          component={'div'}/>
                                            <MyNormalText text={' e minuti '}/>
                                            <Field name={`opening.${day}.to.minute`}
                                                   style={inputStyle}
                                                   type={'text'}/>
                                            <ErrorMessage name={`opening.${day}.to.minute`}
                                                          component={'div'}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Orario di rientro'}
                                              bold={true}/>
                                <div>
                                    <MyNormalText
                                        text={'Le ore e i minuti devono essere separati da \'punto\' e non \'due punti\', esempio corretto: 10.30'}/>
                                </div>
                                <div>
                                    <Field name={'returnTime'}
                                           style={inputStyle}
                                           type={'text'}/>
                                    <ErrorMessage name="returnTime"
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Numero ordinamento'}
                                              bold={true}/>
                                <div>
                                    <Field name={'sorting'}
                                           style={inputStyle}
                                           type={'number'}
                                           placeholder={'e.g., 10'}/>
                                    <ErrorMessage name={'sorting'}
                                                  component={'div'}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Orari di arrivo'}
                                              bold={true}/>
                                <div>
                                    <MyNormalText
                                        text={'Le ore e i minuti devono essere separati da \'punto\' e non \'due punti\', esempio corretto: 10.30'}/>
                                </div>
                                <FieldArray name={'arrivalTime'}
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.arrivalTime.map((arr, index) => (
                                                        <div key={index}>
                                                            <MyNormalText
                                                                text={'Orario di arrivo n. ' + (index + 1) + ' '}/>
                                                            <Field name={`arrivalTime.${index}.time`}
                                                                   style={inputStyle}
                                                                   placeholder="e.g., 9.00"/>
                                                            <ErrorMessage name={`arrivalTime.${index}.time`}
                                                                          component={'div'}/>
                                                            <button type={'button'}
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    style={{marginLeft: "10px"}}>
                                                                X
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button type={'button'}
                                                            onClick={() => arrayHelpers.push({time: ""})}
                                                            style={{marginTop: "10px"}}>
                                                        +
                                                    </button>
                                                </div>
                                            )}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Noleggio con più negozi?'}
                                              bold={true}/>
                                <div>
                                    <Field name={'isMultiStoreRent'}
                                           style={inputStyle}
                                           type={'checkbox'}/>
                                    <ErrorMessage name={'isMultiStoreRent'}
                                                  component={'div'}/>
                                </div>
                                <FieldArray name={'linkedStores'}
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.linkedStores.map((slot, index) => (
                                                        <div key={index}
                                                             style={{
                                                                 ...pageStyle.subSection,
                                                                 backgroundColor: primaryColorFirstAlternative
                                                             }}>
                                                            <div>
                                                                <MyNormalText text={'RentId '}/>
                                                                <Field
                                                                    name={`linkedStores.${index}.rentId`}
                                                                    style={inputStyle}
                                                                    placeholder={'rentId'}/>
                                                                <ErrorMessage
                                                                    name={`linkedStores.${index}.rentId`}
                                                                    component={'div'}/>
                                                            </div>
                                                            <div>
                                                                <MyNormalText text={'Name '}/>
                                                                <Field
                                                                    name={`linkedStores.${index}.name`}
                                                                    style={inputStyle}
                                                                    placeholder={'name'}/>
                                                                <ErrorMessage
                                                                    name={`linkedStores.${index}.name`}
                                                                    component={'div'}/>
                                                            </div>
                                                            <div>
                                                                <MyNormalText text={'Email '}/>
                                                                <Field
                                                                    name={`linkedStores.${index}.email`}
                                                                    style={inputStyle}
                                                                    placeholder={'email'}/>
                                                                <ErrorMessage
                                                                    name={`linkedStores.${index}.email`}
                                                                    component={'div'}/>
                                                            </div>
                                                            <button type={'button'}
                                                                    onClick={() => arrayHelpers.remove(index)}>
                                                                X
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button type={'button'}
                                                            onClick={() => arrayHelpers.push({
                                                                description: "",
                                                                from: "",
                                                                to: "",
                                                                type: "",
                                                                slotCode: 0,
                                                            })}
                                                            style={{marginTop: 10}}>
                                                        Add Slot
                                                    </button>
                                                </div>
                                            )}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Configurazione slot giornata singola'}
                                              bold={true}/>
                                <div style={{marginTop: 10}}>
                                    <MyNormalText text={'Slot types'}
                                                  bold={true}/>
                                </div>
                                {Object.values(singleRentType)
                                    .map((value, index) => {
                                        return (
                                            <div key={index.toString()}>
                                                <MyNormalText text={'- ' + value}/>
                                            </div>
                                        )
                                    })}
                                <div style={{marginTop: 10}}>
                                    <MyNormalText text={'Slot codes'}
                                                  bold={true}/>
                                    <div>
                                        <MyNormalText
                                            text={'Devono essere numeri interi dallo 0 in poi, tutti diversi'}/>
                                    </div>
                                </div>
                                <FieldArray name={'rentSlotConfiguration'}
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.rentSlotConfiguration.map((slot, index) => (
                                                        <div key={index}
                                                             style={{
                                                                 ...pageStyle.subSection,
                                                                 backgroundColor: primaryColorFirstAlternative
                                                             }}>
                                                            <div>
                                                                <MyNormalText text={'Descrizione '}/>
                                                                <Field
                                                                    name={`rentSlotConfiguration.${index}.description`}
                                                                    style={inputStyle}
                                                                    placeholder={'Description'}/>
                                                                <ErrorMessage
                                                                    name={`rentSlotConfiguration.${index}.description`}
                                                                    component={'div'}/>
                                                            </div>
                                                            <MyNormalText text={'Dalle ore '}/>
                                                            <Field name={`rentSlotConfiguration.${index}.from`}
                                                                   style={inputStyle}
                                                                   placeholder="Start time"
                                                                   type={'text'}/>
                                                            <ErrorMessage name={`rentSlotConfiguration.${index}.from`}
                                                                          component={'div'}/>
                                                            <MyNormalText text={' alle ore '}/>
                                                            <Field name={`rentSlotConfiguration.${index}.to`}
                                                                   style={inputStyle}
                                                                   placeholder={'End time'}
                                                                   type={'text'}/>
                                                            <ErrorMessage name={`rentSlotConfiguration.${index}.to`}
                                                                          component={'div'}/>
                                                            <div>
                                                                <MyNormalText text={'Tipo '}/>
                                                                <Field name={`rentSlotConfiguration.${index}.type`}
                                                                       style={inputStyle}
                                                                       placeholder="Slot type"
                                                                       type={'text'}/>
                                                                <ErrorMessage
                                                                    name={`rentSlotConfiguration.${index}.type`}
                                                                    component={'div'}/>
                                                            </div>
                                                            <div>
                                                                <MyNormalText text={'Slot code '}/>
                                                                <Field name={`rentSlotConfiguration.${index}.slotCode`}
                                                                       style={inputStyle}
                                                                       placeholder="Slot code"
                                                                       type={'number'}/>
                                                                <ErrorMessage
                                                                    name={`rentSlotConfiguration.${index}.slotCode`}
                                                                    component={'div'}/>
                                                            </div>
                                                            <button type={'button'}
                                                                    onClick={() => arrayHelpers.remove(index)}>
                                                                X
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button type={'button'}
                                                            onClick={() => arrayHelpers.push({
                                                                description: "",
                                                                from: "",
                                                                to: "",
                                                                type: "",
                                                                slotCode: 0,
                                                            })}
                                                            style={{marginTop: 10}}>
                                                        Add Slot
                                                    </button>
                                                </div>
                                            )}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Configurazione slot giornata singola'}
                                              bold={true}/>
                                {["german", "english", "italian"].map((lang) => (
                                    <FieldArray key={lang}
                                                name={`generalInfosV2.${lang}`}
                                                render={(arrayHelpers) => (
                                                    <div style={{
                                                        ...pageStyle.subSection,
                                                        backgroundColor: primaryColorFirstAlternative
                                                    }}>
                                                        <MyNormalText
                                                            text={lang.charAt(0).toUpperCase() + lang.slice(1) + ' '}
                                                            bold={true}/>
                                                        {values.generalInfosV2[lang].map((info, index) => (
                                                            <div key={index}>
                                                                <MyNormalText text={'Info ' + (index + 1) + ' '}/>
                                                                <Field name={`generalInfosV2.${lang}.${index}.info`}
                                                                       style={inputStyle}
                                                                       placeholder={'Enter info text'}/>
                                                                <ErrorMessage
                                                                    name={`generalInfosV2.${lang}.${index}.info`}
                                                                    component={'div'}/>
                                                                <button type={'button'}
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                        style={{marginLeft: 10}}>
                                                                    X
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button type={'button'}
                                                                onClick={() => arrayHelpers.push({info: ""})}
                                                                style={{marginTop: 10}}>
                                                            +
                                                        </button>
                                                    </div>
                                                )}/>
                                ))}
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Cover (JPG)'}
                                              bold={true}/>
                                <div style={{...pageStyle.subSection, backgroundColor: primaryColorFirstAlternative}}>
                                    <input type={'file'}
                                           accept={'image/jpeg'}
                                           onChange={handleFileChange}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                                <MyNormalText text={'Password'}
                                              bold={true}/>
                                <div>
                                    <MyNormalText text={'Deve essere lunga almeno 6 caratteri'}/>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <input style={globalElementInputStyle.customInput}
                                           type={'text'}
                                           value={password}
                                           placeholder={'Password'}
                                           onChange={(e) => handleInsertPassword(e)}/>
                                </div>
                            </div>
                            <button style={{...pageStyle.submitButton, backgroundColor: primaryColor}}
                                    type={'submit'}
                                    disabled={!isValid}>
                                <MyNormalText text={'Salva'}/>
                            </button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'grid',
        gap: 10
    },
    section: {
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {
        marginTop: 10,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    input: {
        borderWidth: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        fontSize: 16,
        padding: 3,
        marginTop: 3,
        marginLeft: 3
    },
    submitButton: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS,
        borderWidth: 0
    }
}

export default CreateRentSection
