import React, {useEffect, useRef, useState} from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import moment from "moment";
import {httpsCallable} from "firebase/functions";
import {db, functions} from "../../firebase";
import MyTimeline from "./myTimeline";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {
    backgroundPalette,
    dashboardSectionLinks,
    GLOBAL_BORDER_RADIUS,
    orderStatus,
    websitePages
} from "../../costants";
import NewBookings from "./newBookings";
import MyItems from "./myItems";
import History from "./history/history";
import Profile from "./profile/profile";
import ChatList from "./chat/chatList";
import ChatFooter from "./chat/chatFooter";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import BookingDetail from "./booking/bookingDetail";
import {fromMomentDate, now, toMomentDate} from "../../global/dates";
import MyNormalText from "../commons/myNormalText";
import Premium from "./premium/premium";

// css to render here for the dashboard
import '../../css/myTimelineCassClass.css';
import '../../css/myCalendarCssClass.css';
import '../../css/myReactTimeStyle.css';
import {useGlobalState} from "../../global/globalState";
import AsyncStorage from "@react-native-async-storage/async-storage";
import DashboardLeftSection from "./dashboardLeftSection";
import SuperUserAdmin from "./superuseradmin/superUserAdmin";
import useMyEscapeListener from "../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../global/listeners/useMyClickOutListener";
import DashboardAdminCommunication from "./dashboardAdminCommunication";
import {executeAt6AM} from "../../global/executeAt6AM";
import MyExperiences from "./myExperiences";

const monthNames = {
    it: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ]
}

moment.updateLocale('it', {
    months: monthNames.it,
    weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']
})

function Dashboard({rentUser, reloadUser, accountType, isMobileView, switchProfile}) {

    const navigate = useNavigate()

    //global state
    const {globalState, setGlobalState} = useGlobalState()

    //current section
    const location = useLocation()
    const [currentPageUrl, setCurrentPageUrl] = useState('')

    //new bookings incoming
    const [newBookings, setNewBookings] = useState([])
    const areThereNewBookings = newBookings.length > 0

    //bookings timeline
    const initialStateParsedItemsForTimeline = []
    const [parsedItemsForTimeline, setParsedItemsForTimeline] = useState(initialStateParsedItemsForTimeline)
    const [rangeSelectedMoment, setRangeSelectedMoment] = useState({
        from: now(),
        to: now().add(1, 'month')
    })
    const [expandTimeline, setExpandTimeline] = useState(true)
    const [loadingTimelineBookings, setLoadingTimelineBookings] = useState(false)
    const [monthYearHistory, setMonthYearHistory] = useState([])
    const monthYearSelectedForTimelineInitialValue = ""
    const [monthYearStringSelectedForTimeline, setMonthYearStringSelectedForTimeline] = useState(monthYearSelectedForTimelineInitialValue)
    const timelineIsOpened = monthYearStringSelectedForTimeline !== monthYearSelectedForTimelineInitialValue

    //bookings history
    const [bookingsHistory, setBookingsHistory] = useState([])
    const [monthYearStringSelectedForHistory, setMonthYearStringSelectedForHistory] = useState("")

    // search history
    const [searchValue, setSearchValue] = useState("")
    const [bookingsSearched, setBookingsSearched] = useState([])
    const [loadingSearch, setLoadingSearch] = useState(false)

    // my items
    const [itemsForPremiumSection, setItemsForPremiumSection] = useState([])
    const [itemsForMyTimeline, setItemsForMyTimeline] = useState([])
    const [itemsForMyItems, setItemsForMyItems] = useState([])
    const [itemsWithArchivedForMyItems, setItemsArchivedForMyItems] = useState([])
    const [loadingItems, setLoadingItems] = useState(true)

    // property to refresh items
    const [refreshItems, setRefreshItems] = useState(false)

    // chat
    const [chats, setChats] = useState([])
    const chatListRef = useRef(null)
    const chatFooterRef = useRef(null)
    const [activeChats, setActiveChats] = useState([])
    let openChatInitialValue = {}
    const [openChat, setOpenChat] = useState(openChatInitialValue)

    // booking detail popup
    const {idBooking} = useParams()
    const [bookingIdDetailToOpen, setBookingIdDetailToOpen] = useState(idBooking)
    const [openBookingDetail, setOpenBookingDetail] = useState(!!idBooking)

    //premium settings
    const [premiumPalette, setPremiumPalette] = useState({})
    const primaryColor = premiumPalette.primaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    //rent communications messages
    const [communications, setCommunications] = useState([])

    useMyEscapeListener(() => setOpenChat(false))
    useMyClickOutListener(chatFooterRef, () => setOpenChat(false))

    useEffect(() => {
        const unsubscribeToNewBookings = subscribeToNewBookings()
        const unsubscribeToChats = subscribeToNewChat()
        return () => {
            unsubscribeToNewBookings()
            unsubscribeToChats()
        }
    }, [rentUser])

    useEffect(() => {
        getRentMonthYearBookingHistory()
    }, [rentUser])

    useEffect(() => {
        if (location.pathname === websitePages.DASHBOARD) {
            setTimeout(() => {
                navigate(dashboardSectionLinks.NEW_BOOKINGS)
            }, 500)
        }
    }, [location.pathname])

    useEffect(() => {
        const section = location.pathname
        setCurrentPageUrl(section)
    }, [location.pathname])

    useEffect(() => {
        AsyncStorage.getItem('premium-background-palette')
            .then(value => {
                    if (value) {
                        setPremiumPalette(JSON.parse(value))
                    } else {
                        setPremiumPalette(backgroundPalette.firstPalette)
                    }
                }
            )
    }, [])

    useEffect(() => {
        getItems()
    }, [rentUser, refreshItems])

    useEffect(() => {
        const rentCommunicationView = httpsCallable(functions, 'rentCommunicationView')
        const fetchCommunications = () => {
            const request = {rentId: rentUser.rentId}
            rentCommunicationView(request)
                .then(response => {
                    const communications = response.data.communications
                    setCommunications(communications)
                })
        }

        fetchCommunications()
        return executeAt6AM(fetchCommunications)

    }, [rentUser])

    function subscribeToNewBookings() {
        const q = query(collection(db, "rent_app_new_bookings", rentUser.rentId, "bookings"),
            where("status", "==", orderStatus.PENDING))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const bewBookings = []
            querySnapshot.forEach((doc) => {
                bewBookings.push(doc.data())
            })
            setNewBookings(bewBookings)
        })
        return () => unsubscribe()
    }

    function subscribeToNewChat() {
        const today = moment().toDate()
        const q = query(collection(db, "rent_app_my_chats", rentUser.rentId, "chats"),
            where('endDate', '>', today),
            where('isActive', '==', true))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chats = []
            let newMessages = 0
            querySnapshot.forEach((doc) => {
                const newChat = doc.data();
                newMessages += newChat.newMessagesAmount
                chats.push(newChat)
            })
            setChats(chats)
        })
        return () => unsubscribe()
    }

    function getRentMonthYearBookingHistory() {
        const getRentMonthYearBookingHistory = httpsCallable(functions, 'getRentMonthYearBookingHistory')
        getRentMonthYearBookingHistory({rentId: rentUser.rentId})
            .then((result) => {
                const monthYearList = result.data.monthYearList
                setMonthYearHistory(monthYearList)
            })
    }

    function getItems() {
        const rentItemsListView = httpsCallable(functions, 'rentItemsListView')
        rentItemsListView({
            rentId: rentUser.rentId,
            archived: true
        }).then((result) => {
            const items = result.data.items
            const itemsNotArchived = items.filter(item => !item.archived)
            setItemsForPremiumSection(itemsNotArchived)
            setItemsForMyTimeline(itemsNotArchived)
            setItemsForMyItems(itemsNotArchived)

            const itemsArchived = items.filter(item => item.archived)
            setItemsArchivedForMyItems(itemsArchived)

            setLoadingItems(false)
        }).catch(() => setLoadingItems(false))
    }

    function changeSection(link) {
        if (parsedItemsForTimeline.length > 0) {
            setExpandTimeline(false)
        }
        setCurrentPageUrl(link)
        navigate(link)
    }

    function getMonthYearStrings() {
        function getMonthName(monthId) {
            const monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
            return monthNames[monthId]
        }

        let result = []
        if (monthYearHistory.length > 0) {
            for (let value of monthYearHistory) {
                let [month, year] = value.split('-')
                const date = new Date(year, month - 1, 1)
                const monthYearString = getMonthName(date.getMonth()) + ' ' + date.getFullYear()
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
                const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                const firstDayString = `${firstDay.getDate()}-${firstDay.getMonth() + 1}-${firstDay.getFullYear()}`
                const lastDayString = `${lastDay.getDate()}-${lastDay.getMonth() + 1}-${lastDay.getFullYear()}`
                result.push({value: {from: firstDayString, to: lastDayString}, label: monthYearString})
            }
        }

        return result
    }

    function handleFetchAcceptedBookingsAndParseThemForTimeline(isFromPremiumPage, value) {
        if (isFromPremiumPage && !timelineIsOpened) {
            return
        }

        let firstDay
        let lastDay

        if (value) {
            firstDay = value.value.from
            lastDay = value.value.to
            setMonthYearStringSelectedForTimeline(value.label)
            setRangeSelectedMoment({
                from: toMomentDate(firstDay),
                to: toMomentDate(lastDay)
            })
        } else {
            firstDay = fromMomentDate(rangeSelectedMoment.from)
            lastDay = fromMomentDate(rangeSelectedMoment.to)
        }

        setLoadingTimelineBookings(true)
        const rentBookingsByDateView = httpsCallable(functions, 'rentBookingsByDateView')
        rentBookingsByDateView({rentId: rentUser.rentId, dateFilter: {from: firstDay, to: lastDay}})
            .then((response) => {
                const bookings = response.data.bookings
                setParsedItemsForTimeline([])
                let parsedItems = []
                bookings.forEach((booking, bookingIndex) => {
                    if (booking.status === orderStatus.ACCEPTED) {
                        booking.items.forEach((item, itemIndex) => {
                            const orderDatesFrom = booking.orderDates.from
                            const orderDatesTo = booking.orderDates.to
                            let itemTitle
                            let startTime
                            let toTime
                            let startTimeHours
                            let startTimeMinutes
                            let toTimeHours
                            let toTimeMinutes
                            if (booking.isSingleDayRent) {
                                const singleDaySlot = booking.orderDates.singleDaySlot
                                startTimeHours = parseInt(singleDaySlot.from)
                                startTimeMinutes = singleDaySlot.from.split(".")[1]
                                toTimeHours = parseInt(singleDaySlot.to)
                                toTimeMinutes = singleDaySlot.to.split(".")[1]

                                itemTitle = 'Dalle ' + startTimeHours + ':' + startTimeMinutes + ' alle ' + toTimeHours + ':' + toTimeMinutes

                                startTime = toMomentDate(orderDatesFrom).add(startTimeHours, 'hours').add(startTimeMinutes, 'minutes')
                                toTime = toMomentDate(orderDatesFrom).add(toTimeHours, 'hours').add(toTimeMinutes, 'minutes')
                            } else {
                                itemTitle = 'Dal ' + orderDatesFrom + ' al ' + orderDatesTo

                                startTime = toMomentDate(orderDatesFrom)
                                toTime = toMomentDate(orderDatesTo).add(1, 'day')
                            }
                            parsedItems.push({
                                id: bookingIndex + '-' + itemIndex,
                                group: item.item.id,
                                title: itemTitle,
                                start_time: startTime,
                                end_time: toTime,
                                onClick: () => handleItemDoubleClickOnBookingTimeline(booking),
                                client: booking.client,
                                items: booking.items.map(item => item.item),
                                backgroundColor: booking.onCalendar?.backgroundColor
                            })
                        })
                    }
                })
                setLoadingTimelineBookings(false)
                setParsedItemsForTimeline(parsedItems)
            }).catch(() => setLoadingTimelineBookings(false))
    }

    function handleItemDoubleClickOnBookingTimeline(booking) {
        setBookingIdDetailToOpen(booking.bookingId)
        setOpenBookingDetail(true)
    }

    function handleActivateChat(chatToAdd) {
        if (!activeChats.filter((chat) => chat.chatId === chatToAdd.chatId).length) {
            setActiveChats([...activeChats, ...[chatToAdd]])
        }

        if (chatToAdd.newMessagesAmount > 0) {
            const updateRentAppChat = httpsCallable(functions, 'updateRentAppChat')
            updateRentAppChat({rentId: rentUser.rentId, chatId: chatToAdd.chatId, newMessagesAmount: 0})
        }

        handleToggleChat(chatToAdd)
    }

    function handleDeleteChat(chat) {
        handleToggleChat(chat)
        const index = activeChats.indexOf(chat)
        if (index > -1) {
            activeChats.splice(index, 1);
            setActiveChats([...activeChats])
        }
    }

    function handleToggleChat(chat) {
        if (chat.chatId !== openChat.chatId) {
            setOpenChat(chat)
        } else {
            setOpenChat(openChatInitialValue)
        }
    }

    function handleSelectChat(chatId) {
        const chatsFiltered = chats.filter(chat => chat.chatId === chatId)
        const chatToAdd = chatsFiltered[0]
        if (!activeChats.filter((chat) => chat.chatId === chatToAdd.chatId).length) {
            setActiveChats([...activeChats, ...chatsFiltered])
        }
        if (chatToAdd) {
            setOpenChat(chatToAdd)
        } else {
            alert('Nessuna chat attiva per questo ordine.')
        }
    }

    function changePremiumPalette(newPalette) {
        AsyncStorage.setItem('premium-background-palette', JSON.stringify(newPalette))
        setGlobalState(prevState => ({...prevState, premiumBackgroundPalette: newPalette}))
        setPremiumPalette(newPalette)
    }

    function changeGlobalFontSizeCoefficient(coefficient) {
        AsyncStorage.setItem('premium-fontsize-coefficient', JSON.stringify(coefficient))
        setGlobalState(prevState => ({...prevState, premiumFontSizeCoefficient: coefficient}))
    }

    function calculateLeftMargin() {
        return {left: isMobileView ? 120 : 'calc(100% / 5.2)'}
    }

    function handleRefreshItems() {
        setRefreshItems(!refreshItems)
    }

    function handleDismissCommunicationByIndex(index) {
        communications.splice(index, 1)
        setCommunications([...communications])
    }

    return (
        <div style={{...pageStyle.dashboardPageContainer, backgroundColor: primaryColor}}>
            <div style={pageStyle.dashboardMainDiv}>
                <div>
                    <DashboardLeftSection premiumPalette={premiumPalette}
                                          isMobileView={isMobileView}
                                          accountType={accountType}
                                          rentUser={rentUser}
                                          newBookings={newBookings}
                                          areThereNewBookings={areThereNewBookings}
                                          changeSection={changeSection}
                                          changePremiumPalette={changePremiumPalette}
                                          changeGlobalFontSizeCoefficient={changeGlobalFontSizeCoefficient}
                                          sectionSelected={currentPageUrl}/>
                </div>
                <div style={{...pageStyle.containerCentralSection, ...(calculateLeftMargin())}}>
                    {isMobileView ?
                        <></> :
                        <div style={{
                            ...pageStyle.containerTimelineSection,
                            backgroundColor: primaryColorFirstAlternative
                        }}>
                            <div style={{marginBottom: 20}}>
                                <MyNormalText text={'Timeline'}
                                              bold={true}
                                              fontSize={22}/>
                            </div>
                            <MyTimeline items={itemsForMyTimeline.slice()}
                                        premiumPalette={premiumPalette}
                                        monthSelectedMoment={rangeSelectedMoment}
                                        expand={expandTimeline}
                                        setExpand={setExpandTimeline}
                                        timelineItems={parsedItemsForTimeline}
                                        monthYearStrings={getMonthYearStrings()}
                                        monthYearStringSelected={monthYearStringSelectedForTimeline}
                                        handleFetchAcceptedBookingsAndParseThemForTimeline={handleFetchAcceptedBookingsAndParseThemForTimeline}
                                        loading={loadingTimelineBookings}/>
                        </div>}
                    <DashboardAdminCommunication communications={communications}
                                                 navigate={navigate}
                                                 handleRemoveCommunication={handleDismissCommunicationByIndex}
                                                 premiumPalette={premiumPalette}/>
                    <div style={pageStyle.containerCentralSubSection}>
                        <BookingDetail rentUser={rentUser}
                                       premiumPalette={premiumPalette}
                                       bookingId={bookingIdDetailToOpen}
                                       isOpen={openBookingDetail}
                                       setIsOpen={setOpenBookingDetail}
                                       handleSelectChat={handleSelectChat}/>
                        <div style={{display: 'flex'}}>
                            <div
                                style={{...pageStyle.containerSections, backgroundColor: primaryColorFirstAlternative}}>
                                <Routes>
                                    <Route path={dashboardSectionLinks.NEW_BOOKINGS}
                                           element={<NewBookings rentUser={rentUser}
                                                                 premiumPalette={premiumPalette}
                                                                 newBookings={newBookings}/>}/>
                                    <Route path={dashboardSectionLinks.PREMIUM + '/*'}
                                           element={<Premium rentUser={rentUser}
                                                             items={itemsForPremiumSection}
                                                             setItems={setItemsForPremiumSection}
                                                             currentPageUrl={currentPageUrl}
                                                             premiumPalette={premiumPalette}
                                                             handleSelectChat={handleSelectChat}
                                                             reloadTimelineBookings={handleFetchAcceptedBookingsAndParseThemForTimeline}/>}/>
                                    <Route path={dashboardSectionLinks.HISTORY + '/*'}
                                           element={<History user={rentUser}
                                                             currentPageUrl={currentPageUrl}
                                                             premiumPalette={premiumPalette}
                                                             bookings={bookingsHistory}
                                                             setBookings={setBookingsHistory}
                                                             bookingsSearched={bookingsSearched}
                                                             setBookingsSearched={setBookingsSearched}
                                                             searchValue={searchValue}
                                                             setSearchValue={setSearchValue}
                                                             loadingSearchBooking={loadingSearch}
                                                             setLoadingSearchBooking={setLoadingSearch}
                                                             historyBookings={bookingsSearched}
                                                             setHistoryBookings={setBookingsSearched}
                                                             monthYearStrings={getMonthYearStrings()}
                                                             monthYearStringSelected={monthYearStringSelectedForHistory}
                                                             setMonthYearStringSelected={setMonthYearStringSelectedForHistory}
                                                             handleSelectChat={handleSelectChat}/>}/>
                                    <Route path={dashboardSectionLinks.MY_ITEMS + '/*'}
                                           element={<MyItems rentUser={rentUser}
                                                             currentPageUrl={currentPageUrl}
                                                             premiumPalette={premiumPalette}
                                                             items={itemsForMyItems.slice()}
                                                             setItems={setItemsForMyItems}
                                                             itemsArchived={itemsWithArchivedForMyItems}
                                                             setItemsArchivedForMyItems={setItemsArchivedForMyItems}
                                                             refreshItems={handleRefreshItems}
                                                             loadingItems={loadingItems}/>}/>
                                    <Route path={dashboardSectionLinks.MY_EXPERIENCES + '/*'}
                                           element={<MyExperiences rentUser={rentUser}
                                                                   currentPageUrl={currentPageUrl}
                                                                   premiumPalette={premiumPalette}
                                                                   loadingItems={loadingItems}/>}/>
                                    <Route path={dashboardSectionLinks.PROFILE}
                                           element={<Profile rentUser={rentUser}
                                                             switchProfile={switchProfile}
                                                             reloadUser={reloadUser}
                                                             premiumPalette={premiumPalette}/>}/>
                                    <Route path={dashboardSectionLinks.SUPER_USER_ADMIN + '/*'}
                                           element={<SuperUserAdmin rentUser={rentUser}
                                                                    switchProfile={switchProfile}
                                                                    currentPageUrl={currentPageUrl}
                                                                    premiumPalette={premiumPalette}/>}/>
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={pageStyle.containerChat}>
                    <ChatList chatRef={chatListRef}
                              isMobileView={isMobileView}
                              premiumPalette={premiumPalette}
                              handleActivateChat={handleActivateChat}
                              chats={chats}/>
                </div>
            </div>
            <ChatFooter chatFooterRef={chatFooterRef}
                        premiumPalette={premiumPalette}
                        rentUser={rentUser}
                        activeChats={activeChats}
                        openChat={openChat}
                        handleActivateChat={handleActivateChat}
                        handleToggleChat={handleToggleChat}
                        handleDeleteChat={handleDeleteChat}/>
        </div>
    )
}

const pageStyle = {
    dashboardPageContainer: {
        position: 'fixed',
        display: 'flex',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'scroll'
    },
    dashboardMainDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        width: '100%'
    },
    containerCentralSection: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 30,
        position: 'absolute',
        right: 'calc(100% / 5.4)'
    },
    containerCentralSubSection: {
        display: 'flex',
        flexDirection: 'column'
    },
    coverImage: {
        objectFit: 'cover',
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10
    },
    containerChat: {
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        marginRight: 20,
        marginTop: 10
    },
    containerTimelineSection: {
        padding: 10,
        paddingTop: 30,
        borderEndStartRadius: GLOBAL_BORDER_RADIUS,
        borderEndEndRadius: GLOBAL_BORDER_RADIUS
    },
    containerSections: {
        marginBottom: 20,
        padding: 10,
        width: '100%',
        alignSelf: 'flex-start',
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    marginBottom10: {
        marginBottom: 10
    }
}

export default Dashboard;
