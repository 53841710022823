import React, {useEffect, useState} from "react";
import Loader from "../commons/loader";
import {dashboardMyExperiencesLinks, GLOBAL_BORDER_RADIUS_ACCENTUATED} from "../../costants";
import MainButton from "../commons/mainButton";
import ButtonsToOrderItems from "./premium/buttonsToOrderItems";
import {Route, Routes, useNavigate} from "react-router-dom";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import ExperienceBox from "./experience/experienceBox";

function MyExperiences({rentUser, currentPageUrl, premiumPalette}) {

    const [experiences, setExperiences] = useState([])
    const [experiencesArchived, setExperiencesArchived] = useState([])
    const [refreshExperiences, setRefreshExperiences] = useState(true)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor

    const currentlyOnVetrina = currentPageUrl.includes(dashboardMyExperiencesLinks.VETRINA)
    const currentlyOnArchivedSection = currentPageUrl.includes(dashboardMyExperiencesLinks.ARCHIVE)

    useEffect(() => {
        fetchExperiences(false, setExperiences)
        fetchExperiences(true, setExperiencesArchived)
    }, [refreshExperiences])

    function fetchExperiences(archived, setExperiences) {
        const rentExperiencesListView = httpsCallable(functions, 'rentExperiencesListView')
        const request = {
            rentId: rentUser.rentId,
            archived: archived
        }
        rentExperiencesListView(request)
            .then((response) => {
                const experiences = response.data.experiences
                setExperiences(experiences)
                setLoading(false)
            })
    }

    function getExperiences(experiences) {
        return (
            <>
                <ButtonsToOrderItems items={currentlyOnVetrina ? experiences : experiencesArchived}
                                     setItems={currentlyOnVetrina ? setExperiences : setExperiencesArchived}
                                     premiumPalette={premiumPalette}
                                     buttonsColor={primaryColor}/>
                {loading ?
                    <Loader/> :
                    <div style={pageStyle.experienceList}>
                        {experiences.map((experience) => {
                            return (
                                <div key={experience.experienceId}>
                                    <ExperienceBox rentUser={rentUser}
                                                   experience={experience}
                                                   premiumPalette={premiumPalette}
                                                   refreshExperiences={() => setRefreshExperiences(!refreshExperiences)}
                                                   openOperations={true}
                                                   width={'100%'}
                                                   imageHeight={120}/>
                                </div>
                            )
                        })}
                    </div>}
            </>
        )
    }

    function handleChangeMyItemsSection(section) {
        navigate(section)
    }

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.buttonsContainer}>
                <div>
                    <MainButton text={'In Vetrina (' + experiences.length + ')'}
                                action={() => handleChangeMyItemsSection(dashboardMyExperiencesLinks.VETRINA)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentlyOnVetrina ? secondaryColor : primaryColor}/>
                </div>
                <div>
                    <MainButton text={'Archiviati (' + experiencesArchived.length + ')'}
                                action={() => handleChangeMyItemsSection(dashboardMyExperiencesLinks.ARCHIVE)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentlyOnArchivedSection ? secondaryColor : primaryColor}/>
                </div>
            </div>
            <div>
                <Routes>
                    <Route path={dashboardMyExperiencesLinks.VETRINA}
                           element={getExperiences(experiences)}/>
                    <Route path={dashboardMyExperiencesLinks.ARCHIVE}
                           element={getExperiences(experiencesArchived)}/>
                </Routes>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30
    },
    buttonsContainer: {
        display: 'flex',
        gap: 10
    },
    experienceList: {
        marginTop: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 10
    }
}

export default MyExperiences
