import React from "react";
import Select from "react-select";
import Timeline from "react-calendar-timeline";
import Loader from "../commons/loader";
import {globalElementSelectStyle} from "../../global/style/globalElementSelectStyle";
import MainButton from "../commons/mainButton";
import {GLOBAL_BORDER_RADIUS} from "../../costants";

function MyTimeline({
                        items,
                        expand,
                        setExpand,
                        timelineItems,
                        monthSelectedMoment,
                        monthYearStrings,
                        monthYearStringSelected,
                        loading,
                        handleFetchAcceptedBookingsAndParseThemForTimeline,
                        premiumPalette
                    }) {

    const timeTheCalendarCanZoomToInMillisecond = 60 * 60 * 1000 * 24
    const itemSelectedForTheTimeline = timelineItems.length > 0

    function getItemListParsed() {
        function sortItemsByAssignedRentCode(items) {
            const itemsWithAssignedRentCode = items.filter(obj => obj.assignedRentCode).sort((a, b) => a.assignedRentCode.localeCompare(b.assignedRentCode))
            const itemsWithoutAssignedRentCode = items.filter(obj => !obj.assignedRentCode)
            return [...itemsWithAssignedRentCode, ...itemsWithoutAssignedRentCode]
        }

        return sortItemsByAssignedRentCode(items).map(item => {
            const itemTitle = (item.assignedRentCode ? ('[' + item.assignedRentCode + ']') : '[-]') + ' [' + item.size + '] ' + item.name
            return {id: item.id, title: itemTitle}
        })
    }

    function createToolTip(item) {
        return item.client.name + '\n\n' +
            item.title + '\n\n' +
            item.items.map(item => {
                const assignedRentCode = item.assignedRentCode ? item.assignedRentCode : '-'
                return '[' + assignedRentCode + ']' + ' [' + item.size + '] ' + item.name
            }).join('\n')
    }

    const customItemRenderer = ({item, itemContext, getItemProps, getResizeProps}) => {
        const tooltipMessage = createToolTip(item)
        const eventStyle = {
            backgroundColor: item.backgroundColor,
            background: item.backgroundColor,
            borderRadius: 20,
            border: 0,
            zIndex: 0
        }
        return (
            <div {...getItemProps({style: eventStyle})}
                 onClick={() => item.onClick()}
                 title={tooltipMessage}>
            </div>
        )
    }

    const containerTimelineStyle = {
        height: expand ? undefined : (itemSelectedForTheTimeline ? 150 : 0),
        color: premiumPalette.isDarkMode ? 'white' : undefined,
        borderRadius: GLOBAL_BORDER_RADIUS,
        overflowY: 'auto',
        marginTop: 10
    }

    function handlePressExpandTimeline() {
        setExpand(!expand)
    }

    return (
        <div style={pageStyle.container}>
            {loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                <div style={{flex: 1, marginRight: 10}}>
                    <Select styles={globalElementSelectStyle}
                            placeholder={monthYearStringSelected ? monthYearStringSelected : 'Scegli un mese per visualizzare la timeline'}
                            onChange={(newValue) => handleFetchAcceptedBookingsAndParseThemForTimeline(false, newValue)}
                            options={monthYearStrings}/>
                </div>
                <div>
                    <MainButton
                        image={expand ? require("../../imgs/actions/arrow-up.png") : require("../../imgs/actions/arrow-down.png")}
                        action={handlePressExpandTimeline}
                        imageHeight={20}
                        imageWidth={20}
                        backgroundColor={premiumPalette.primaryColorFirstAlternative}/>
                </div>
            </div>
            <div style={containerTimelineStyle}>
                {itemSelectedForTheTimeline ?
                    <Timeline groups={getItemListParsed()}
                              items={timelineItems}
                              itemRenderer={customItemRenderer}
                              sidebarWidth={240}
                              defaultTimeStart={monthSelectedMoment.from}
                              defaultTimeEnd={monthSelectedMoment.to}
                              minZoom={timeTheCalendarCanZoomToInMillisecond}
                              maxZoom={timeTheCalendarCanZoomToInMillisecond}
                              canMove={false}
                              canResize={false}/> :
                    <></>}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }
}

export default MyTimeline
